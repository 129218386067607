import Login from "./pages/login/Login";
import './style/dark.scss';
import { useContext } from "react";
import { DarkModeContext } from "./context/darkModeContext";
import { useAuthDataStore } from "./stores/store";
import Layout from "./components/layout/Layout";

function App() {
  const {darkMode} = useContext(DarkModeContext);
  const authTokenStore = useAuthDataStore((state) => state.authToken);
  const authToken = localStorage.getItem('authToken');
  const logoutUser = useAuthDataStore((state) => state.logoutUser);

  if (authToken || authTokenStore) {
    return (
      <div className={darkMode ? "app dark" : "app"}>
        <Layout onLogout={logoutUser}/>
      </div>
    );
  } else {
    return <Login/>
  }
  
}

export default App;
