import {Link} from 'react-router-dom';

export const userColumns = [
    { field: "id", headerName: "Trans ID", width: 80 },
    {
      field: "date",
      headerName: "Date",
      width: 100,
    },
    {
      field: "party",
      // headerName: "User",
      headerName: "Party Name",
      width: 230,
      renderCell: (params) => {
        return (
          <div className="cellWithImg">
            <img className="cellImg" src={params.row.img} alt="avatar" />
            <Link to={`/parties/${params.row.id}`}>{params.row.username}</Link>
          </div>
        );
      },
    },
    {
      field: "mtype",
      headerName: "Metal Type",
      width: 120,
    },
    {
      field: "weight",
      headerName: "Weight (gms)",
      width: 110,
    },
    {
      field: "s_weight",
      headerName: "Src. Weight (gms)",
      width: 140,
    },
    {
      field: "s_charge",
      headerName: "Src. Charge",
      width: 100,
    },
    {
      field: "status",
      headerName: "Status",
      width: 100,
      renderCell: (params) => {
        return (
          <div className={`cellWithStatus ${params.row.status}`}>
            {params.row.status}
          </div>
        );
      },
    },
    {
      field: "trxns_type",
      headerName: "Trans Type",
      width: 100,
      renderCell: (params) => {
        return (
          <div className={`cellWithStatus ${params.row.trxns_type}`}>
            {params.row.trxns_type}
          </div>
        );
      },
    },
    {
      field: "remark",
      headerName: "Remarks",
      width: 350,
    },
    {
      field: "inserttime",
      headerName: "Insert Time",
      width: 150
    },
    {
      field: "updatetime",
      headerName: "Update Time",
      width: 150
    },
    {
      field: "approvetime",
      headerName: "Approve Time",
      width: 150
    }
  ];
  
  //temporary data
  export const userRows = [
    {
      id: 1101,
      date: '01/05/2022',
      username: "Snow",
      img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
      mtype: "Fine Gold",
      weight: "1120 grams",
      s_weight: "",
      s_charge: "",
      trxns_type: "credit",
      status: "active",
      remark: "This is a testing remark.This is a testing remark.This is a testing remark.This is a testing remark.This is a testing remark.",
      email: "1snow@gmail.com",
      age: 35,
    },
    {
      id: 1102,
      date: '03/05/2022',
      username: "Jamie Lannister",
      img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
      mtype: "Fine Gold",
      weight: "1247 grams",
      s_weight: "",
      s_charge: "",
      trxns_type: "credit",
      status: "passive",
      remark: "This is a testing remark.",
      email: "2snow@gmail.com",
      age: 42,
    },
    {
      id: 1103,
      date: '07/05/2022',
      username: "Lannister",
      img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
      mtype: "Fine Gold",
      weight: "2100 grams",
      s_weight: "120 grams",
      s_charge: "2000",
      trxns_type: "credit",
      status: "pending",
      remark: "This is a testing remark.",
      email: "3snow@gmail.com",
      age: 45,
    },
    {
      id: 1104,
      date: '10/05/2022',
      username: "Stark",
      img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
      mtype: "Raw",
      weight: "120 grams",
      s_weight: "",
      s_charge: "",
      trxns_type: "debit",
      remark: "This is a testing remark.",
      email: "4snow@gmail.com",
      status: "active",
      age: 16,
    },
    {
      id: 1105,
      date: '11/05/2022',
      username: "Targaryen",
      img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
      mtype: "Bangles",
      weight: "200 grams",
      s_weight: "250 grams",
      s_charge: "2100",
      trxns_type: "debit",
      remark: "This is a testing remark.",
      email: "5snow@gmail.com",
      status: "passive",
      age: 22,
    },
    {
      id: 1106,
      date: '12/05/2022',
      username: "Melisandre",
      img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
      mtype: "Bangles",
      weight: "1500 grams",
      s_weight: "",
      s_charge: "",
      trxns_type: "credit",
      remark: "This is a testing remark.",
      email: "6snow@gmail.com",
      status: "active",
      age: 15,
    },
    {
      id: 1107,
      date: '15/05/2022',
      username: "Clifford",
      img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
      mtype: "Fine Gold",
      weight: "1020 grams",
      s_weight: "50 grams",
      s_charge: "1800",
      trxns_type: "credit",
      remark: "This is a testing remark.",
      email: "7snow@gmail.com",
      status: "passive",
      age: 44,
    },
    {
      id: 1108,
      date: '20/05/2022',
      username: "Frances",
      img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
      mtype: "Raw",
      weight: "500 grams",
      s_weight: "",
      s_charge: "",
      trxns_type: "debit",
      remark: "This is a testing remark.",
      email: "8snow@gmail.com",
      status: "active",
      age: 36,
    },
    {
      id: 1109,
      date: '21/05/2022',
      username: "Roxie",
      img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
      mtype: "Raw",
      weight: "700 grams",
      s_weight: "",
      s_charge: "",
      trxns_type: "credit",
      remark: "This is a testing remark.",
      email: "snow@gmail.com",
      status: "pending",
      age: 65,
    },
    {
      id: 1110,
      date: '30/05/2022',
      username: "Roxie",
      img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
      mtype: "Fine Gold",
      weight: "1120 grams",
      s_weight: "50 grams",
      s_charge: "1750",
      trxns_type: "credit",
      remark: "This is a testing remark.",
      email: "snow@gmail.com",
      status: "active",
      age: 65,
    },
  ];