import React from "react";
import SCLOGO from "../../assets/img/sc-logo.png";
import "./Login.scss";
import { useState } from "react";
import { useAuthDataStore } from "../../stores/store";

const Login = () => {
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const loginUser = useAuthDataStore((state) => state.loginUser);

  const handleSubmit = async (e) => {
    e.preventDefault();
    loginUser({username, password});
  };

  return (
    <React.Fragment>
      <div className="auth-main-component">
        <div className="auth-main-child">
          <div className="auth-header">
            <div className="auth-header-logo">
              <img src={SCLOGO} alt="" className="auth-header-logo-img" />
            </div>
            <h1 className="auth-header-title">Welcome to SBR</h1>
            {/* <p className="auth-header-subtitle">
              Sign-in to your account and start the adventure
            </p> */}
          </div>
          <div className="auth-body">
            <form className="auth-form-validation" onSubmit={handleSubmit}>
              <div className="input-field">
                <label htmlFor="email" className="input-label">
                  Username
                </label>
                <input
                  type="text"
                  className="input-control"
                  id="email"
                  placeholder="example@gmail.com"
                  autoComplete="off"
                  required
                  onChange={(e) => setUsername(e.target.value)}
                />
              </div>
              <div className="input-field">
                <label htmlFor="password" className="input-label">
                  Password
                </label>
                <input
                  type="password"
                  name="password"
                  id="password"
                  className="input-control"
                  placeholder="Password"
                  autoComplete="off"
                  required
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <button type="submit" className="btn-submit">
                Sign in
              </button>
            </form>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Login;