import {Link} from 'react-router-dom';

export const userColumns = [
    { field: "party_id", headerName: "Party ID", width: 130 },
    {
      field: "party_name",
      // headerName: "User",
      headerName: "Party Name",
      width: 230,
      renderCell: (params) => {
        return (
          <div className="cellWithImg">
            {/* <img className="cellImg" src={params.row.img} alt="avatar" /> */}
            <img className="cellImg" src="https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500" alt="avatar" />
            <Link to={`/parties/${params.row.party_id}`}>{params.row.party_name}</Link>
          </div>
        );
      },
    },
    {
      field: "email",
      headerName: "Email",
      width: 230,
    },
    // {
    //   field: "age",
    //   headerName: "Age",
    //   width: 100,
    // },
    // {
    //   field: "address",
    //   headerName: "Address",
    //   width: 100,
    // },
    {
      field: "phone",
      headerName: "Phone",
      width: 200,
    },
    {
      field: "status",
      headerName: "Status",
      width: 100,
      renderCell: (params) => {
        return (
          <div className={`cellWithStatus ${params.row.status}`}>
            {params.row.status}
          </div>
        );
      },
    },
  ];
  
  //temporary data
  export const userRows = [
    {
      id: 1,
      partyId: 1143155,
      username: "Snow",
      img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
      status: "active",
      email: "1snow@gmail.com",
      phone: "+91 97025 11552",
      age: 35,
    },
    {
      id: 2,
      partyId: 2235235,
      username: "Jamie Lannister",
      img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
      email: "2snow@gmail.com",
      status: "passive",
      phone: "+91 97025 11552",
      age: 42,
    },
    {
      id: 3,
      partyId: 2342353,
      username: "Lannister",
      img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
      email: "3snow@gmail.com",
      status: "active",
      phone: "+91 97025 11552",
      age: 45,
    },
    {
      id: 4,
      username: "Stark",
      partyId: 2357741,
      img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
      email: "4snow@gmail.com",
      status: "active",
      phone: "+91 97025 11552",
      age: 16,
    },
    {
      id: 5,
      partyId: 2342355,
      username: "Targaryen",
      img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
      email: "5snow@gmail.com",
      status: "passive",
      phone: "+91 97025 11552",
      age: 22,
    },
    {
      id: 6,
      partyId: 1143155,
      username: "Melisandre",
      img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
      email: "6snow@gmail.com",
      status: "active",
      phone: "+91 97025 11552",
      age: 15,
    },
    {
      id: 7,
      partyId: 2235235,
      username: "Clifford",
      img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
      email: "7snow@gmail.com",
      status: "passive",
      phone: "+91 97025 11552",
      age: 44,
    },
    {
      id: 8,
      partyId: 2342353,
      username: "Frances",
      img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
      email: "8snow@gmail.com",
      phone: "+91 97025 11552",
      status: "active",
      age: 36,
    },
    {
      id: 9,
      partyId: 2357741,
      username: "Roxie",
      img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
      email: "snow@gmail.com",
      phone: "+91 97025 11552",
      status: "passive",
      age: 65,
    },
    {
      id: 10,
      partyId: 2342355,
      username: "Roxie",
      img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
      email: "snow@gmail.com",
      phone: "+91 97025 11552",
      status: "active",
      age: 65,
    },
  ];