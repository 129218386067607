import './DashboardChart.scss';
// import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import { ResponsiveContainer, BarChart, Bar, CartesianGrid, XAxis, YAxis, Tooltip, Legend } from 'recharts';

const DashboardChart = ({aspect, title, aggTrxns}) => {

  return (
    <div className='dashboard-chart'>
        {/* <div className='title'>{title}</div> */}
        <ResponsiveContainer width="100%" aspect={aspect}>
            <BarChart width={730} height={250} data={aggTrxns} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
            {/* <defs>
                <linearGradient id="credit" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#F4869C" stopOpacity={0.8}/>
                <stop offset="95%" stopColor="#F4869C" stopOpacity={0}/>
                </linearGradient>
                <linearGradient id="debit" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8}/>
                <stop offset="95%" stopColor="#82ca9d" stopOpacity={0}/>
                </linearGradient>
                <linearGradient id="balance" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#68BBE3" stopOpacity={0.8}/>
                <stop offset="95%" stopColor="#68BBE3" stopOpacity={0}/>
                </linearGradient>
            </defs> */}
            <XAxis dataKey="partyName" stroke='gray'/>
            <YAxis stroke='gray'/>
            <CartesianGrid strokeDasharray="3 3" className='chartGrid'/>
            <Tooltip />
            <Legend />
            <Bar dataKey="deposits" fill="#B6FDB6" />
            <Bar dataKey="withdrawals" fill="#FBBCB9" />
            <Bar dataKey="balance" fill="#FBE9AE" />
            {/* <Area type="monotone" dataKey="deposit" stroke="#F4869C" fillOpacity={1} fill="url(#credit)" />
            <Area type="monotone" dataKey="withdrawl" stroke="#82ca9d" fillOpacity={1} fill="url(#debit)" />
            <Area type="monotone" dataKey="balance" stroke="#68BBE3" fillOpacity={1} fill="url(#balance)" /> */}
            </BarChart>
      </ResponsiveContainer>
    </div>
  )
}

export default DashboardChart;