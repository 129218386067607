import React, { useState, useEffect, useRef } from 'react';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { Toolbar } from 'primereact/toolbar';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputNumber } from 'primereact/inputnumber';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import ReactLoading from "react-loading";

import { Dropdown as BootDropdown } from 'react-bootstrap';
import BootstrapSwitchButton from 'bootstrap-switch-button-react'

import './DataTableDemo.scss';
import { useRefinaryTrxnStore, useRefinaryMasterDataStore, useRefinaryDataByPartyStore } from '../../stores/refinaryStore';

const ExcelJS = require('exceljs');

const deletedTransactions = [];
const addedTransactions = [];

let updatedRowData = {};
const updatedTransactions = [];

const RefinaryDataTable = props => {

    const tzoffset = (new Date()).getTimezoneOffset() * 60000;
    
    const serviceCharge = useRefinaryTrxnStore((state) => state.serviceCharge);
    const refinaryTrxnsData = useRefinaryTrxnStore((state) => state.refinaryTrxnsData);
    const getRefinaryTrxnsData = useRefinaryTrxnStore((state) => state.getRefinaryTrxnsData);
    const addAddedTransactions = useRefinaryTrxnStore((state) => state.addAddedTransactions);
    const updateTransaction = useRefinaryTrxnStore((state) => state.updateTransaction);
    const removeDeletedTransaction = useRefinaryTrxnStore((state) => state.removeDeletedTransaction);
    const cudTransactions = useRefinaryTrxnStore((state) => state.cudTransactions);

    const isTrxnsDataLoading = useRefinaryTrxnStore((state) => state.isTrxnsDataLoading);

    const getAggDataByParty = useRefinaryDataByPartyStore((state) => state.getAggDataByParty);
    const getAggChartdataByParty = useRefinaryDataByPartyStore((state) => state.getAggChartdataByParty);

    const metals = useRefinaryMasterDataStore((state) => state.metals);
    const uows = useRefinaryMasterDataStore((state) => state.uows);
    const trxnService = useRefinaryMasterDataStore((state) => state.service);
    const getService = useRefinaryMasterDataStore((state) => state.getService);

    const serviceChargeDetails = useRefinaryMasterDataStore((state) => state.serviceChargeDetails);
    const getServiceChargeDetails = useRefinaryMasterDataStore((state) => state.getServiceChargeDetails);

    let fileUploaderRef = null;
    const emptyTransaction = {
        firmId: 'FRM001',
        partyId: props.partyId,
        serviceTrxnId: '',
        serviceTrxnDate: '',
        metal: '',
        metalId: '',
        serviceId: '',
        service: '',
        serviceChargeId: '',
        serviceChargeCategory: '',
        serviceChargeUOM: '',
        basisWeight: 0,
        serviceChargeType: '',
        serviceChargeRate: 0,
        weight: 0,
        finePercentage: 0,
        serviceWeight: 0,
        overriddenServiceWeight: 0,
        UOW: '',
        serviceCharge: 0,
        overriddenServiceCharge: 0,
        remarks: '',
        createdBy: 'administrator',
        dateCreated: new Date(new Date().getTime() + 5.5 * 60 * 60000).toISOString(),
        modifiedBy: 'administrator',
        dateModified: new Date(new Date().getTime() + 5.5 * 60 * 60000).toISOString(),
    };

    const tableColumns = props.cols;

    // const metals = [
    //     { metal: 'GOLD-FINE', metalId: 'MTL001' },
    //     { metal: 'GOLD-ORD', metalId: 'MTL002' },
    //     { metal: 'SILVER-FINE', metalId: 'MTL003' },
    //     { metal: 'SILVER-ORD', metalId: 'MTL004' },
    // ];

    // const trxn_types = [
    //     { transaction: 'DEPOSIT', code: 'DEPOSIT' },
    //     { transaction: 'WITHDRAWAL', code: 'WITHDRAWAL' },
    // ];

    // const uows = [
    //     { uow: 'GRAM', code: 'gram' },
    //     { uow: 'KILLOGRAM', code: 'killogram' },
    // ];



    const [importedCols, setImportedCols] = useState([{ field: '', header: 'Header' }]);
    const [importedData, setImportedData] = useState([]);

    const [dateRange, setDateRange] = useState([new Date((new Date()).getFullYear(), (new Date()).getMonth()-3, 1), new Date()]);

    const [selectedColumns, setSelectedColumns] = useState(tableColumns);
    // const [dynamicColumns, setDynamicColumns] = useState();
    const [products, setProducts] = useState(null);
    const [transaction, setTransaction] = useState(emptyTransaction);
    const [transactions, setTransactions] = useState(refinaryTrxnsData);
    // const [productDialog, setProductDialog] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const [transactionDialog, setTransactionDialog] = useState(false);
    const [deleteTransactionDialog, setDeleteTransactionDialog] = useState(false);
    const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);
    const [product, setProduct] = useState(emptyTransaction);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [selectedTransactions, setSelectedTransactions] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [metal, setMetal] = useState(metals[0]);
    const [trxnType, setTrxnType] = useState('');
    const [wghtVal, setWghtVal] = useState(0);
    const [finePerVal, setFinePerVal] = useState(0);
    const [srvChrgRate, setSrvChrgRate] = useState(serviceCharge.serviceChargeRate);
    const [srvWghtVal, setSrvWghtVal] = useState(0);
    const [overSrvWghtVal, setOverSrvWghtVal] = useState(0);
    const [srvCharge, setSrvCharge] = useState(0);
    const [overSrvCharge, setOverSrvCharge] = useState(0);
    const [service, setService] = useState('');
    const [serviceChargeCategory, setServiceChargeCategory] = useState('');
    const [serviceChargeType, setServiceChargeType] = useState('');
    const [serviceChargeRate, setServiceChargeRate] = useState(0);
    const [uow, setUow] = useState(uows[0]);
    const [serviceChargeUOM, setServiceChargeUOM] = useState('');
    const [remarks, setRemarks] = useState('');
    const [basisWeight, setBasisWeight] = useState(0);
    const [date, setDate] = useState(new Date());
    const toast = useRef(null);
    const dt = useRef(null);

    const resetState = () => {
        setDate(new Date());
        setMetal(metals[0]);
        setTrxnType('');
        setWghtVal(0);
        setFinePerVal(0);
        setSrvWghtVal(0);
        setUow(uows[0]);
        setRemarks('');
        setSubmitted(false);
    };


    useEffect(() => {
        generateAddedTransactionsArray();
        if (dateRange[0] && dateRange[1]) {
            let tzoffset = (new Date()).getTimezoneOffset() * 60000;
            getRefinaryTrxnsData(props.partyId, 
              (new Date(dateRange[0] - tzoffset).toISOString().substring(0,10).replace(/-/g,'')), 
              (new Date(dateRange[1] - tzoffset).toISOString().substring(0,10).replace(/-/g,'')));
          }
    }, [JSON.stringify(importedData), dateRange, props.partyId]);
    // }, [JSON.stringify(tableColumns), JSON.stringify(importedData), dateRange, props.partyId]);

    useEffect(() => {
        setSrvChrgRate(serviceCharge?.serviceChargeRate);
    }, [JSON.stringify(serviceCharge)])


    const columnToggleDropdown = tableColumns.map(col => {
        return <BootDropdown.Item key={col.field}>
            <BootstrapSwitchButton checked={!col.hidden} size="xs"
                onChange={(checked) => {
                    var objIndex = tableColumns.findIndex((obj => obj.field === col.field));
                    tableColumns[objIndex].hidden = !checked;
                    setSelectedColumns({...tableColumns});
                }}
            />
            <span className="col-header-name">{col.header}</span>
        </BootDropdown.Item>
    })


    // Input Form Functions ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const handleFinePercentInput = finePerValue => {
        const serviceWeightCalulation = wghtVal * (finePerValue / 100);
        const servWght = !isNaN(serviceWeightCalulation) && isFinite(serviceWeightCalulation) ? serviceWeightCalulation : 0;
        setSrvWghtVal(servWght);
        setOverSrvWghtVal(servWght);
        setSrvCharge((servWght*srvChrgRate)/100);
        setOverSrvCharge((servWght*srvChrgRate)/100);
        setFinePerVal(finePerValue);
    };

    const handleServWghtInput = servWghtVal => {
        const finePercentCalculation = (servWghtVal / wghtVal) * 100;
        const finePercent = !isNaN(finePercentCalculation) && isFinite(finePercentCalculation) ? finePercentCalculation : 0;
        setFinePerVal(finePercent);
        setSrvWghtVal(servWghtVal);
        setOverSrvWghtVal(servWghtVal);
        setSrvCharge((servWghtVal*srvChrgRate)/100);
        setOverSrvCharge((servWghtVal*srvChrgRate)/100);
    };

    const handleOverriddenServWghtInput = overSrcWghtVal => {
        const serviceWeightCalulation = wghtVal * (finePerVal / 100);
        const servWght = !isNaN(serviceWeightCalulation) && isFinite(serviceWeightCalulation) ? serviceWeightCalulation : 0;
        setOverSrvWghtVal(overSrcWghtVal);
        // setOverSrvCharge((overSrcWghtVal-servWght) + (servWght*srvChrgRate)/100);
        setOverSrvCharge((overSrcWghtVal-servWght) + srvCharge);
    };

    const handleMetalWeightInput = metalWeight => {
        setWghtVal(metalWeight);

        const serviceWeightCalulation = metalWeight * (finePerVal / 100);
        const serviceWeight = !isNaN(serviceWeightCalulation) && isFinite(serviceWeightCalulation) ? serviceWeightCalulation : 0;
        setSrvWghtVal(serviceWeight);

        const finePercentCalculation = (srvWghtVal / metalWeight) * 100;
        const finePercent = !isNaN(finePercentCalculation) && isFinite(finePercentCalculation) ? finePercentCalculation : 0;
        setFinePerVal(finePercent);
    };
    // Input Form Functions ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const formatCurrency = value => {
        return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    };

    const openNew = () => {
        setTransaction(emptyTransaction);
        resetState();
        setSubmitted(false);
        setTransactionDialog(true);
    };

    const hideDialog = () => {
        setSubmitted(false);
        setTransactionDialog(false);
    };

    const hideDeleteProductDialog = () => {
        setDeleteTransactionDialog(false);
    };

    const hideDeleteProductsDialog = () => {
        setDeleteProductsDialog(false);
    };

    const findTrxnType = (deposits, withdrawals) => {
        if (deposits !== 0) {
            return "DEPOSIT";
        } else if (withdrawals !== 0) {
            return "WITHDRAWAL";
        } else {
            return null;
        }
    }

    const getServiceById = (service_id) => {
        getService(service_id);
        return trxnService.service;
    }

    const getServiceChargeDetailsData = (service_charge_id, service_id, party_id) => {
        getServiceChargeDetails(service_charge_id, service_id, party_id);
        return serviceChargeDetails;
    }

    const getMetalId = (metalName) => {
        let metalObj = metals.filter(el => el.metal === metalName)[0];
        return metalObj.metalId;
    }

    const calculateServiceWeight = (obj) => {
        const serviceWeightCalulation = obj.weight * (obj.finePercentage / 100);
        const calculatedServiceWeight = !isNaN(serviceWeightCalulation) && isFinite(serviceWeightCalulation) ? serviceWeightCalulation : 0;
        return calculatedServiceWeight;
    }

    const calculateServiceCharge = (obj) => {
        const calculatedServiceCharge = (obj.weight * (obj.finePercentage/100)) * srvChrgRate;
        return calculatedServiceCharge;
    }

    const calculateOverridenServiceCharge = (obj) => {
        const calculatedOverriddenServiceCharge = (obj.overriddenServiceWeight * (obj.finePercentage/100)) * srvChrgRate;
        return calculatedOverriddenServiceCharge;
    }

    const generateAddTransactionObj = (obj) => {
        if(obj.metal && obj.serviceTrxnDate && obj.serviceWeight){
            const addTrxnObj = {
                firmId: 'FRM001',
                partyId: props.partyId,
                serviceTrxnId: generateTrxnId(),
                serviceTrxnDate: obj.serviceTrxnDate,
                metalId: getMetalId(obj.metal),
                metal: obj.metal,
                UOM: 'GRAM',
                serviceId: 'SRC001', //GOLD-REFINERY  service
                service: getServiceById('SRC001'),
                // service: trxnService.service,
                serviceChargeId: serviceCharge.serviceChargeId,
                serviceChargeCategory: serviceCharge.serviceChargeCategory,
                serviceChargeUOM: serviceCharge.serviceChargeUOM,
                serviceChargeType: serviceCharge.serviceChargeType,
                serviceChargeRate: serviceCharge.serviceChargeRate,
                basisWeight: serviceCharge.basisWeight,
                weight: obj.weight,
                finePercentage: obj.finePercentage,
                serviceWeight: calculateServiceWeight(obj),
                overriddenServiceWeight: obj.overriddenServiceWeight,
                serviceCharge: calculateServiceCharge(obj),
                overriddenServiceCharge: calculateOverridenServiceCharge(obj),
                orderCount: 0,
                status: 'APPROVED',
                remarks: obj.remarks,
                activeStatus: 'A',
                createdBy: ('createdBy' in obj) ? obj.createdBy : 'administrator',
                dateCreated: new Date(new Date().getTime() + 5.5 * 60 * 60000).toISOString(),
                modifiedBy: ('modifiedBy' in obj) ? obj.modifiedBy : (('createdBy' in obj) ? obj.createdBy : 'administrator'),
                dateModified: new Date(new Date().getTime() + 5.5 * 60 * 60000).toISOString(),
                type: 'added-trxns'
            };
            return addTrxnObj;
        }
    }

    const addTransaction = () => {
        setSubmitted(true);

        if (metal.metal && srvWghtVal) {
            const transaction = {
                firmId: 'FRM001',
                partyId: props.partyId,
                serviceTrxnId: generateTrxnId(),
                serviceTrxnDate: convertDate(),
                metalId: metal.metalId,
                metal: metal.metal,
                UOM: uow.UOM,
                serviceId: 'SRC001', //GOLD-REFINERY  service
                service: getServiceById('SRC001'),
                serviceChargeId: serviceCharge.serviceChargeId,
                serviceChargeCategory: serviceCharge.serviceChargeCategory,
                serviceChargeUOM: serviceCharge.serviceChargeUOM,
                serviceChargeType: serviceCharge.serviceChargeType,
                serviceChargeRate: serviceCharge.serviceChargeRate,
                basisWeight: serviceCharge.basisWeight,
                weight: wghtVal,
                finePercentage: finePerVal,
                serviceWeight: srvWghtVal,
                overriddenServiceWeight: overSrvWghtVal,
                serviceCharge: srvCharge,
                overriddenServiceCharge: overSrvCharge,
                orderCount: 0,
                status: 'APPROVED',
                remarks: remarks,
                activeStatus: 'A',
                createdBy: 'administrator',
                dateCreated: new Date(new Date().getTime() + 5.5 * 60 * 60000).toISOString(),
                modifiedBy: 'administrator',
                dateModified: new Date(new Date().getTime() + 5.5 * 60 * 60000).toISOString(),
                type: 'added-trxns'
            };
            addedTransactions.push(transaction);
            // const trxns = transactions;
            // trxns.push(transaction);
            setTransactionDialog(false);
            // setTransactions(trxns);
            addAddedTransactions(transaction);
            // setTransaction(emptyTransaction);
            resetState();
        }
        // setSubmitted(false);
    };

    const handleUpdateTransaction = () => {
        const transaction = {
            firmId: updatedRowData.firmId,
            partyType: props.type,
            partyId: props.partyId,
            partyName: props.partyName,
            serviceTrxnId: updatedRowData.serviceTrxnId,
            serviceTrxnDate: convertDate(),
            metalId: metal.metalId,
            metal: metal.metal,
            UOM: uow.UOM,
            serviceId: 'SRC001',
            service: service,
            serviceChargeId: updatedRowData.serviceChargeId,
            serviceChargeCategory: serviceChargeCategory,
            serviceChargeUOM: serviceChargeUOM,
            serviceChargeType: serviceChargeType,
            serviceChargeRate: serviceChargeRate,
            weight: wghtVal,
            basisWeight: basisWeight,
            finePercentage: finePerVal,
            serviceWeight: srvWghtVal,
            overriddenServiceWeight: overSrvWghtVal,
            serviceCharge: srvCharge,
            overriddenServiceCharge: overSrvCharge,
            orderCount: 0,
            status: updatedRowData.status,
            remarks: remarks,
            activeStatus: 'A',
            createdBy: updatedRowData.createdBy,
            dateCreated: updatedRowData.dateCreated,
            modifiedBy: 'administrator',
            dateModified: new Date(new Date().getTime() + 5.5 * 60 * 60000).toISOString(),
            type: 'updated-trxns'
        };

        // Checking if the new updated trxn is already present in addedTransactions and updatedTransactions, and if exists then replace it with the updated one
        let exists_add = addedTransactions.some(el => el.serviceTrxnId === transaction.serviceTrxnId);
        let exists_update = updatedTransactions.some(el => el.serviceTrxnId === transaction.serviceTrxnId);

        if (exists_add) {
            const replaceIndex = addedTransactions.findIndex(el => el.serviceTrxnId === transaction.serviceTrxnId);
            transaction.type = 'added-trxns';
            addedTransactions.splice(replaceIndex, 1, transaction);
        } else if (exists_update) {
            const replaceIndex = updatedTransactions.findIndex(el => el.serviceTrxnId === transaction.serviceTrxnId);
            updatedTransactions.splice(replaceIndex, 1, transaction);
        } else {
            updatedTransactions.push(transaction);
        }

        // let updateIndex = transactions.findIndex(el => el.serviceTrxnId === transaction.serviceTrxnId);
        // transactions[updateIndex] = transaction;
        updateTransaction(transaction);
        setTransactionDialog(false);
        // setTransactions(trxns);
        setTransaction(emptyTransaction);
        setIsUpdating(false);
    };

    const editTransaction = transactionRowData => {

        let tDate = transactionRowData.serviceTrxnDate.toString();
        setDate(new Date(tDate.substring(0, 4), tDate.substring(4, 6) - 1, tDate.substring(6, 8)));
        setMetal(metals.filter(el => el.metalId === transactionRowData.metalId)[0]);
        // setTrxnType(trxn_types.filter(el => el.transaction === transactionRowData.trxnType)[0]);
        setWghtVal(transactionRowData.weight);
        setOverSrvWghtVal(transactionRowData.overriddenServiceWeight);
        setUow(uows.filter(el => el.uow === transactionRowData.UOW)[0]);
        setFinePerVal(transactionRowData.finePercentage);
        setSrvWghtVal(transactionRowData.serviceWeight);
        setSrvCharge(transactionRowData.serviceCharge);
        setOverSrvCharge((transactionRowData.overriddenServiceWeight-transactionRowData.serviceWeight) + transactionRowData.serviceCharge);
        setRemarks(transactionRowData.remarks);
        setService(transactionRowData.service);
        setServiceChargeCategory(transactionRowData.serviceChargeCategory);
        setServiceChargeUOM(transactionRowData.UOM);
        setBasisWeight(transactionRowData.basisWeight);
        setServiceChargeType(transactionRowData.serviceChargeType);
        setServiceChargeRate(transactionRowData.serviceChargeRate);

        updatedRowData = transactionRowData;
        setTransactionDialog(true);
        setIsUpdating(true);
    };

    const confirmDeleteProduct = transaction => {
        setTransaction(transaction);
        setDeleteTransactionDialog(true);
    };

    const deleteInlineTransaction = () => {
        // let _transactions = transactions.filter(val => val.serviceTrxnId !== transaction.serviceTrxnId);
        // setTransactions(_transactions);
        removeDeletedTransaction(transaction);
        let added_exists = addedTransactions.some(el => el.serviceTrxnId === transaction.serviceTrxnId);
        let updated_exists = updatedTransactions.some(el => el.serviceTrxnId === transaction.serviceTrxnId);
        if (added_exists) {
            const removeIndex = addedTransactions.findIndex(el => el.serviceTrxnId === transaction.serviceTrxnId);
            addedTransactions.splice(removeIndex, 1);
        } else if (updated_exists) {
            const removeIndex = updatedTransactions.findIndex(el => el.serviceTrxnId === transaction.serviceTrxnId);
            updatedTransactions.splice(removeIndex, 1);
            deletedTransactions.push(transaction.serviceTrxnId);
        } else {
            deletedTransactions.push(transaction.serviceTrxnId);
        }
        setDeleteTransactionDialog(false);
        setTransaction(emptyTransaction);
        // toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Product Deleted', life: 3000 });
    };

    const generateTrxnId = () => {
        let trxnId = 'STRXN' + new Date().getTime().toString();
        return trxnId;
    };

    const convertDate = () => {
        let tzoffset = new Date().getTimezoneOffset() * 60000;
        let date_time_zone = new Date(date - tzoffset).toISOString().substring(0, 10).replace(/-/g, '');
        return date_time_zone;
    };

    const getDeposits = () => {
        if (trxnType.code === 'DEPOSIT') {
            return srvWghtVal;
        } else {
            return 0;
        }
    };

    const getWithdrawls = () => {
        if (trxnType.code === 'WITHDRAWAL') {
            return srvWghtVal;
        } else {
            return 0;
        }
    };


    const generateAddedTransactionsArray = async() => {
        let sleep = (delay) => new Promise((resolve) => setTimeout(resolve, delay));

        if (importedData.length > 0) {
            for(let i=0; i < importedData.length; i++) {
                await sleep(1000);
                addedTransactions.push(generateAddTransactionObj(importedData[i]));
            }
        }
        
        // const trxns = transactions.concat(addedTransactions);
        // setTransactions(trxns);
        addAddedTransactions(addedTransactions);
        setTransaction(emptyTransaction);
        resetState();
        // setImportedData([]);
    }

    const importExcel = (e) => {
        const file = e.files[0];

        import('xlsx').then(xlsx => {
            const reader = new FileReader();
            reader.onload = (e) => {
                const wb = xlsx.read(e.target.result, { type: 'array' });
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                const data = xlsx.utils.sheet_to_json(ws, { header: 1 });

                // Prepare DataTable
                const cols = data[0];
                data.shift();

                let _importedCols = cols.map(col => ({ field: col, header: toCapitalize(col) }));
                let _importedData = data.map(d => {
                    return cols.reduce((obj, c, i) => {
                        obj[c] = d[i];
                        return obj;
                    }, {});
                });

                _importedData = _importedData.filter(item => item.metal !== undefined && item.serviceTrxnDate !== undefined)

                setImportedCols(_importedCols);
                setImportedData(_importedData);
            };

            reader.readAsArrayBuffer(file);
        });
        fileUploaderRef.clear();
    }

    const toCapitalize = (s) => {
        return s.charAt(0).toUpperCase() + s.slice(1);
    }

    const exportExcel = () => {
        let export_trxn_data = [];
        const export_columns_list = ['serviceTrxnDate', 'metal', 'weight', 'finePercentage', 'serviceWeight', 'overriddenServiceWeight', 'serviceCharge', 'overriddenServiceCharge', 'remarks'];
        let srno = 1;

        // filtering only required columns
        for (let trxn_idx in refinaryTrxnsData) {
            let t = refinaryTrxnsData[trxn_idx];
            let obj = {}
            obj['srno'] = srno
            for (let col_idx in export_columns_list) {
                let col_key = export_columns_list[col_idx];
                obj[col_key] = t[col_key];
            }
            export_trxn_data.push(obj);
            srno += 1;
        }

        // Calculating SUM of serviceWeight and service Charge
        const result = export_trxn_data.reduce(
            (accumulator, transaction) => {
              accumulator.serviceWeight += transaction.serviceWeight;
              accumulator.serviceCharge += transaction.serviceCharge;
              return accumulator;
            },
            { serviceWeight: 0, serviceCharge: 0, overriddenServiceWeight: 0, overriddenServiceCharge: 0}
        );

        const convert_num_to_string_india = (weight) => {
            return Intl.NumberFormat('en-IN').format(weight.toFixed(3));
        };


        const workbook = new ExcelJS.Workbook();
        const sheet = workbook.addWorksheet("Refinary");

        // Merging cells for Sheet Header
        sheet.mergeCells('A1:J2');

        sheet.mergeCells('A3:D4');
        sheet.mergeCells('A5:D6');

        sheet.mergeCells('E3:G4');
        sheet.mergeCells('E5:G6');

        sheet.mergeCells('H3:J4');
        sheet.mergeCells('H5:J6');

        const alignment = {
            horizontal: 'left', 
            vertical: 'middle'
        };

        const font = {
            name: 'Arial Black',
            family: 3,
            size: 12,
        }

        const sheetHeader = sheet.getCell('A1');
        sheetHeader.value = 'Refinary';
        sheetHeader.alignment = {
            horizontal: 'center',
            vertical: 'middle'
        }
        sheetHeader.font = {
            name: 'Arial Black',
            family: 2,
            size: 14
        }

        const headingPartyName = sheet.getCell('A3');
        headingPartyName.value = 'Party Name: '+props.partyName;
        headingPartyName.alignment = alignment;
        headingPartyName.font = font;

        const headingDateRange = sheet.getCell('A5');
        headingDateRange.value = 'Date Range: ' + (new Date(dateRange[0] - tzoffset).toLocaleDateString()) + ' - ' + (new Date(dateRange[1] - tzoffset).toLocaleDateString());
        headingDateRange.alignment = alignment;
        headingDateRange.font = font;

        const headingPhone = sheet.getCell('E3');
        headingPhone.value = 'Phone: ' + props.phone;
        headingPhone.alignment = alignment;
        headingPhone.font = font;

        const headingAddress = sheet.getCell('E5');
        headingAddress.value = 'Address: ' + props.address;
        headingAddress.alignment = alignment;
        headingAddress.font = font;

        const headingDeposits = sheet.getCell('H3');
        headingDeposits.value = 'Total Service Weight: ' + convert_num_to_string_india(result.serviceWeight) + ' grams';
        headingDeposits.alignment = alignment;
        headingDeposits.font = font;

        const headingWithdrawals = sheet.getCell('H5');
        headingWithdrawals.value = 'Total Service Charge: ' + convert_num_to_string_india(result.serviceCharge) + ' grams';
        headingWithdrawals.alignment = alignment;
        headingWithdrawals.font = font;

        const headers = ['Sr. No.', 'Date', 'Metal', 'Weight (g)',  'Fine Percent (%)', 'Service Weight (g)', 'Overridden Service Weight (g)', 'Service Charge (g)', 'Overridden Service Charge (g)', 'Remarks'];
        sheet.addRow(headers);

        sheet.getRow(7).font = {
            name: "Sans serif",
            family: 4,
            size: 12,
            bold: true
        }

        var borderStyles = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" }
        };

        // Add data to worksheet
        const rows_data = export_trxn_data.map((item) => [item?.srno, item?.serviceTrxnDate, item?.metal, item?.weight, item?.finePercentage, item?.serviceWeight, item?.overriddenServiceWeight, item?.serviceCharge, item?.overriddenServiceCharge, item?.remarks]);
        rows_data.forEach((row) => sheet.addRow(row));

        const footerTotals = ['', '', '',  '', '', parseFloat(result.serviceWeight.toFixed(3)), '', parseFloat(result.serviceCharge.toFixed(3)), '', ''];
        sheet.addRow(footerTotals);

        // Setting Column Widths
        const column_width = [10, 15, 15, 15, 20, 25, 35, 25, 35, 40]
        sheet.columns.forEach((column, i) => {
            column.width = column_width[i];
        });

        // Setting frozen header column
        sheet.views = [
            {state: 'frozen', xSplit: 0, ySplit: 7, showGridLines: false}
        ];

        // Adding Auto filter
        sheet.autoFilter = {
            from: 'A7',
            to: 'J7'
        }

        // Adding table border
        let cols = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J']
        let rows = srno;

        for (let i=0; i<cols.length; i++) {
            for (let j=7; j<=rows+7; j++) {
                let cell = cols[i] + j;
                sheet.getCell(cell).border = borderStyles;
            }
        }

        // Filling column background with colour
        for (let i=0; i<cols.length; i++) {
            let cell = cols[i]+'7';
            sheet.getCell(cell).fill = {
                type: "pattern",
                pattern: "darkHorizontal",
                fgColor: {argb: "74a8fc"}
            }
        }

        const footerMergeCellsLeft = 'A'+(srno+7).toString()+':E'+(srno+7).toString();
        sheet.mergeCells(footerMergeCellsLeft);

        // const footerMergeCellsRight = 'I'+(srno+7).toString()+':J'+(srno+7).toString();
        // sheet.mergeCells(footerMergeCellsRight);

        const tc = 'A'+(srno+7).toString();
        const totalCell = sheet.getCell(tc);
        totalCell.value = 'Totals';
        totalCell.alignment = {horizontal: 'center'};
        totalCell.font = font;

        sheet.getRow(srno+7).font = {
            name: "Sans serif",
            family: 4,
            size: 12,
            bold: true
        }
        
        workbook.xlsx.writeBuffer().then(data => {
            const blob = new Blob([data], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheet.sheet",
            });
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a');
            anchor.href = url;
            anchor.download = 'Refinary_'+props.partyName.replace(/ /g,"_")+'_'+(new Date(dateRange[0] - tzoffset).toISOString().substring(0,10).replace(/-/g,''))+'_'+(new Date(dateRange[1] - tzoffset).toISOString().substring(0,10).replace(/-/g,''))+'.xlsx';
            anchor.click();
            window.URL.revokeObjectURL(url);
        })
    }

    const saveForm = async () => {
        try {
            const result = await cudTransactions(addedTransactions, updatedTransactions, deletedTransactions);
            toast.current.show({ severity: 'success', summary: 'Successful', detail: result.data.msg, life: 3000 });
            addedTransactions.splice(0, addedTransactions.length);
            updatedTransactions.splice(0, updatedTransactions.length);
            deletedTransactions.splice(0, deletedTransactions.length);
            // getRefinaryTrxnsData(props.partyId, props.startDate, props.endDate);
            if (dateRange[0] && dateRange[1]) {
                let tzoffset = (new Date()).getTimezoneOffset() * 60000;
                getRefinaryTrxnsData(props.partyId, 
                  (new Date(dateRange[0] - tzoffset).toISOString().substring(0,10).replace(/-/g,'')), 
                  (new Date(dateRange[1] - tzoffset).toISOString().substring(0,10).replace(/-/g,'')));
            }

            let d = new Date();
            let endMonth = d.getMonth()+1;
            let endYear = d.getFullYear();
            let startMonth = endMonth-1;
            let startYear = endYear-1;
            getAggChartdataByParty(props.partyId, startMonth, startYear, endMonth, endYear);
            getAggDataByParty(props.partyId);
        } catch (error) {
            toast.current.show({ severity: 'error', summary: 'Error', life: 3000 });
        }
    };

    // const confirmDeleteSelected = () => {
    //     setDeleteProductsDialog(true);
    // };

    // const deleteSelectedProducts = () => {
    //     // let _products = products.filter(val => !selectedProducts.includes(val));
    //     let _transactions = transactions.filter(val => !selectedTransactions.includes(val));
    //     // setProducts(_products);
    //     setTransactions(_transactions);
    //     // setDeleteProductsDialog(false);
    //     // setSelectedProducts(null);
    //     toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Products Deleted', life: 3000 });
    // };

    const onCategoryChange = e => {
        let _product = { ...product };
        _product['category'] = e.value;
        setProduct(_product);
    };

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _product = { ...product };
        _product[`${name}`] = val;

        setProduct(_product);
    };

    const onInputNumberChange = (e, name) => {
        const val = e.value || 0;
        let _product = { ...product };
        _product[`${name}`] = val;

        setProduct(_product);
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <h4 className="mx-0 my-1">Manage Transactions</h4>
                <div className="my_date_range">
                    {/* <label htmlFor="trxn_date_range" className="trxn_date_range_class">Date Range:</label> */}
                    <Calendar 
                        id="trxn_date_range" 
                        selectionMode="range"
                        dateFormat="dd/mm/yy"
                        maxDate={new Date()} 
                        value={dateRange} 
                        onChange={(e) => setDateRange(e.value)}>
                    </Calendar>
                </div>
            </React.Fragment>
        );
    };

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button icon="pi pi-plus" className="p-button-success mr-2 new-add-custom" onClick={openNew} />
                <Button icon="pi pi-save" className="p-button-info save-custom" onClick={saveForm} />
                <Button icon="pi pi-download" className="p-button-help export-custom" onClick={exportExcel} />
                <FileUpload
                    ref={ref => fileUploaderRef = ref}
                    chooseOptions={{ icon: 'pi pi-upload', className: 'p-button-primary' }}
                    customUpload="true"
                    mode="basic"
                    name="demo[]"
                    auto
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    // chooseLabel="Import"
                    // className="mr-2 inline-block"
                    uploadHandler={importExcel}
                />
            </React.Fragment>
        );
    };

    const filterColumn = () => {
        return (
            <div className="table-header">
                <BootDropdown className="d-inline" autoClose="outside">
                    <BootDropdown.Toggle id="dropdown-autoclose-outside"></BootDropdown.Toggle>
                    <BootDropdown.Menu>
                        {columnToggleDropdown}
                    </BootDropdown.Menu>
                </BootDropdown>
            </div>
        )
    }

    // const imageBodyTemplate = rowData => {
    //     return (
    //         <img
    //             src={`images/product/${rowData.image}`}
    //             onError={e => (e.target.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png')}
    //             alt={rowData.image}
    //             className="product-image"
    //         />
    //     );
    // };

    // const priceBodyTemplate = rowData => {
    //     return formatCurrency(rowData.price);
    // };

    // const ratingBodyTemplate = rowData => {
    //     return <Rating value={rowData.rating} readOnly cancel={false} />;
    // };

    

    const actionBodyTemplate = rowData => {
        return (
            <React.Fragment>
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editTransaction(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning" onClick={() => confirmDeleteProduct(rowData)} />
            </React.Fragment>
        );
    };

    // const header = (
    //     <div className="table-header">
    //         <h3 className="mx-0 my-1">Manage Products</h3>
    //         <BootDropdown className="d-inline mx-4" autoClose="outside">
    //             <BootDropdown.Toggle id="dropdown-autoclose-outside">Show / Hide Columns</BootDropdown.Toggle>
    //             <BootDropdown.Menu>
    //                 {columnToggleDropdown}
    //             </BootDropdown.Menu>
    //         </BootDropdown>
    //     </div>
    // );

    const dynamicColumns = tableColumns.map((col, i) => {
        return <Column key={col.field} 
            field={col.field} 
            header={col.header}
            body={col.body} 
            sortable={col.sortable} 
            filter={col.filter}
            hidden={col.hidden} 
            headerStyle={{justifyContent: col.headerStyle}}
            style={{ minWidth: col.style_width, justifyContent: col.align }}
        />;
    });

    const transactionDialogFooter = (
        <React.Fragment>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            {/* <Button label="Add" icon="pi pi-check" className="p-button-text" onClick={saveProduct} /> */}
            {isUpdating ? (
                <Button label="Update" icon="pi pi-check" className="p-button-text" onClick={handleUpdateTransaction} />
            ) : (
                <Button label="Add" icon="pi pi-check" className="p-button-text" onClick={addTransaction} />
            )}
        </React.Fragment>
    );
    const deleteProductDialogFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteInlineTransaction} />
        </React.Fragment>
    );
    // const deleteProductsDialogFooter = (
    //     <React.Fragment>
    //         <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductsDialog} />
    //         <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedProducts} />
    //     </React.Fragment>
    // );
    // const saveProductsDialogFooter = (
    //     <React.Fragment>
    //         <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductsDialog} />
    //         <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedProducts} />
    //     </React.Fragment>
    // );

    const handleRowClassName = (rowData) => {
        if (rowData.type && rowData.type === 'added-trxns') {
            return {'added-trxns': true};
        } else if (rowData.type && rowData.type === 'updated-trxns') {
            return {'updated-trxns': true};
        } else if (rowData.type && rowData.type === 'deleted-trxns') {
            return {'deleted-trxns': true};
        }
    }

    if ( isTrxnsDataLoading === true ) {
        return (
            <div className="stockledger-page-loading">
              <ReactLoading type="cubes" color="#0000FF" height={100} width={50} /> 
            </div>
        )
    }

    return (
        <div className="datatable-crud">
            <Toast ref={toast} />

            <div className="card">
                <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

                <DataTable
                    ref={dt}
                    value={refinaryTrxnsData}
                    // selection={selectedTransactions}
                    editMode="row"
                    size="small"
                    showGridlines
                    // onSelectionChange={e => setSelectedTransactions(e.value)}
                    dataKey="trxnId"
                    paginator
                    rows={50}
                    rowsPerPageOptions={[10, 25, 50]}
                    scrollable
                    scrollDirection="horizontal"
                    height="70px"
                    filterDisplay="row"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} transactions"
                    // header={header}
                    sortField="serviceTrxnDate"
                    sortOrder={-1}
                    responsiveLayout="scroll"
                    rowHover={true}
                    rowClassName={handleRowClassName}
                >
                    {/* <Column selectionMode="multiple" headerStyle={{ width: '6rem' }} exportable={false} frozen={true}></Column> */}
                    <Column header={filterColumn} body={actionBodyTemplate} exportable={false} frozen={true} style={{ width: '5rem', maxWidth: '5rem' }}></Column>
                    {dynamicColumns}
                </DataTable>
            </div>

            <Dialog
                visible={transactionDialog}
                style={{ width: '500px' }}
                header={'Transaction Entry for ' + props.partyName}
                modal
                className="p-fluid"
                footer={transactionDialogFooter}
                onHide={hideDialog}
            >
                {/* {product.image && <img src={`images/product/${product.image}`} onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={product.image} className="product-image block m-auto pb-3" />} */}
                <div className="field">
                    <label htmlFor="trxn_date">Transaction Date:</label>
                    <Calendar id="trxn_date" dateFormat="dd/mm/yy" placeholder="DD/MM/YYYY" maxDate={new Date()} readOnlyInput value={date} onChange={e => setDate(e.value)}></Calendar>
                    {/* {submitted && !date && <small className="p-error">Transaction Date is required.</small>} */}
                </div>
                <div className="field">
                    <label htmlFor="metal">Metal (Metal_Type/Metal_Form)</label>
                    <Dropdown
                        id="metal"
                        optionLabel="metal"
                        value={metal}
                        options={metals}
                        onChange={e => setMetal(e.value)}
                        placeholder="Select Metal Type"
                        autoFocus
                        className={classNames({ 'p-invalid': submitted && !metal })}
                    />
                    {submitted && !metal && <small className="p-error">Metal is required.</small>}
                </div>
                {/* <div className="field">
                    <label htmlFor="trxn_type">Transaction Type</label>
                    <Dropdown
                        id="trxn_type"
                        optionLabel="transaction"
                        value={trxnType}
                        options={trxn_types}
                        onChange={e => setTrxnType(e.value)}
                        placeholder="Select Transaction Type"
                        autoFocus
                        className={classNames({ 'p-invalid': submitted && !trxnType })}
                    />
                    {submitted && !trxnType && <small className="p-error">Transaction Type is required.</small>}
                </div> */}
                <div className="field wght_section">
                    <div className="wght_left">
                        <label htmlFor="metal_wght" className="metal_weight">
                            Weight
                        </label>
                        <InputNumber id="metal_wght" value={wghtVal} placeholder="0.00" onChange={e => handleMetalWeightInput(e.value)} mode="decimal" locale="en-IN" maxFractionDigits={6} />
                        {/* {submitted && !wghtVal && <small className="p-error">Weight is required.</small>} */}
                    </div>
                    <div className="wght_right">
                        <label htmlFor="uow" className="metal_weight_uow">
                            UOW
                        </label>
                        <Dropdown
                            id="uow"
                            optionLabel="UOM"
                            value={uow}
                            options={uows}
                            onChange={e => setUow(e.value)}
                            placeholder="Select UOW"
                            autoFocus
                            className={classNames({ 'p-invalid': submitted && !uow })}
                        />
                        {/* <InputText id="uow" value="GRAM" disabled required autoFocus className={classNames({ 'p-invalid': submitted && !uow }, {})} size="small"/> */}
                        {/* {submitted && !uow && <small className="p-error">UOW is required.</small>} */}
                    </div>
                </div>
                <div className="field wght_section">
                    <div className="wght_left">
                        <label htmlFor="fine_percent" className="fine_percentage">
                            Fine Percentage
                        </label>
                        <div className="p-inputgroup">
                            {/* <InputNumber id="fine_percent" value={finePerVal} disabled={!wghtVal} placeholder="0.00 %" onChange={(e) => handleFinePercentInput(e.value)} mode="decimal" locale="en-IN" maxFractionDigits={4} /> */}
                            <InputNumber
                                id="fine_percent"
                                value={finePerVal}
                                disabled={false}
                                placeholder="0.00 %"
                                onChange={e => handleFinePercentInput(e.value)}
                                mode="decimal"
                                locale="en-IN"
                                maxFractionDigits={4}
                            />
                            <span className="p-inputgroup-addon">%</span>
                        </div>
                    </div>
                    <div className="wght_right">
                        <label htmlFor="service_rate" className="service_charge_rate">
                            Service Charge Rate
                        </label>
                        <div className="p-inputgroup">
                            <InputNumber id="service_rate" value={srvChrgRate} disabled placeholder="0.00" mode="decimal" locale="en-IN" maxFractionDigits={4} />
                        </div>
                        {/* {submitted && !srvChrgRate && <small className="p-error">Service Weight is required.</small>} */}
                    </div>
                </div>
                <div className="field wght_section">
                    <div className="wght_left">
                        <label htmlFor="service_wght" className="service_weight">Service Weight</label>
                        <div className="p-inputgroup">
                            <InputNumber id="service_wght" value={srvWghtVal} placeholder="0.00" onChange={e => handleServWghtInput(e.value)} mode="decimal" locale="en-IN" maxFractionDigits={4} />
                            <span className="p-inputgroup-addon">g</span>
                        </div>
                        {submitted && !srvWghtVal && <small className="p-error">Service Weight is required.</small>}
                    </div>
                    <div className="wght_left">
                        <label htmlFor="service_chrg" className="service_charge">Service Charge</label>
                        <div className="p-inputgroup">
                            <InputNumber id="service_chrg" value={srvCharge} disabled placeholder="0.00" mode="decimal" locale="en-IN" maxFractionDigits={4} />
                            <span className="p-inputgroup-addon">g</span>
                        </div>
                        {/* {submitted && !srvCharge && <small className="p-error">Service Charge is required.</small>} */}
                    </div>
                </div>
                <div className="field wght_section">
                    <div className="wght_right">
                        <label htmlFor="overridden_service_wght" className="overridden_service_weight">
                            Overridden Service Wght
                        </label>
                        <div className="p-inputgroup">
                            <InputNumber id="overridden_service_wght" value={overSrvWghtVal} placeholder="0.00" onChange={e => handleOverriddenServWghtInput(e.value)} mode="decimal" locale="en-IN" maxFractionDigits={4} />
                            <span className="p-inputgroup-addon">g</span>
                        </div>
                        {/* {submitted && !overSrvWghtVal && <small className="p-error">Service Weight is required.</small>} */}
                    </div>
                    <div className="wght_right">
                        <label htmlFor="overridden_service_chrg" className="overridden_service_charge">
                            Overridden Service Chrg
                        </label>
                        <div className="p-inputgroup">
                            <InputNumber id="overridden_service_chrg" value={overSrvCharge} disabled placeholder="0.00" mode="decimal" locale="en-IN" maxFractionDigits={4} />
                            <span className="p-inputgroup-addon">g</span>
                        </div>
                        {/* {submitted && !overSrvWghtVal && <small className="p-error">Service Weight is required.</small>} */}
                    </div>
                </div>
                <div className="field">
                    <label htmlFor="description">Remarks/Comments</label>
                    <InputTextarea id="description" value={remarks} onChange={e => setRemarks(e.target.value)} required rows={3} cols={20} maxLength={400} />
                    {/* {submitted && !remarks && <small className="p-error">Remarks is required.</small>} */}
                </div>
            </Dialog>

            <Dialog visible={deleteTransactionDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteProductDialogFooter} onHide={hideDeleteProductDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    {product && (
                        <span>
                            Are you sure you want to delete <b>{transaction.name}</b>?
                        </span>
                    )}
                </div>
            </Dialog>

            {/* <Dialog visible={deleteProductsDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteProductsDialogFooter} onHide={hideDeleteProductsDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    {product && <span>Are you sure you want to delete the selected products?</span>}
                </div>
            </Dialog> */}
        </div>
    );
};

export default RefinaryDataTable;
