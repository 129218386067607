import './biaxialchart.scss';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend } from 'recharts';

const BiAxialChart = ({aspect, title, aggTrxns}) => {
  return (
    <div className='chart'>
        {/* <div className='title'>{title}</div> */}
        <ResponsiveContainer width="100%" aspect={aspect}>
        <AreaChart width={730} height={250} data={aggTrxns} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
          <defs>
            <linearGradient id="serviceWeight" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#F4869C" stopOpacity={0.8}/>
              <stop offset="95%" stopColor="#F4869C" stopOpacity={0}/>
            </linearGradient>
            <linearGradient id="serviceCharge" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8}/>
              <stop offset="95%" stopColor="#82ca9d" stopOpacity={0}/>
            </linearGradient>
          </defs>
          <XAxis dataKey="serviceTrxnMonthName" stroke='gray'/>
          <YAxis yAxisId="left" stroke='gray'/>
          <YAxis yAxisId="right" orientation="right" stroke='gray'/>
          <CartesianGrid strokeDasharray="3 3" className='chartGrid'/>
          <Tooltip />
          <Legend />
          <Area type="monotone" yAxisId="left" dataKey="serviceWeight" stroke="#F4869C" fillOpacity={1} fill="url(#credit)" />
          <Area type="monotone" yAxisId="right" dataKey="serviceCharge" stroke="#82ca9d" fillOpacity={1} fill="url(#debit)" />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  )
}

export default BiAxialChart