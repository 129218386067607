import React, { useState, useEffect, useRef } from 'react';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toolbar } from 'primereact/toolbar';

import { useDashboardStore } from '../../stores/dashboardStore';
import './Grid.scss';

const Grid = props => {

    const allPartyDashboardData = useDashboardStore((state) => state.allPartyDashboardData);
    const getAllPartyDashboardData = useDashboardStore((state) => state.getAllPartyDashboardData);
    const dt = useRef(null);

    const getDepositsGramBodyTemplate = rowData => {
        if (rowData.deposits) {
            return `${rowData.deposits?.toLocaleString('en-IN', {minimumFractionDigits: 2})} g`
        } else {
            return ''
        }
    };

    const getWithdrawalsGramBodyTemplate = rowData => {
        if (rowData.withdrawals) {
            return `${rowData.withdrawals?.toLocaleString('en-IN', {minimumFractionDigits: 2})} g`
        } else {
            return ''
        }
    };

    const getBalanceGramBodyTemplate = rowData => {
        if (rowData.balance) {
            return `${rowData.balance?.toLocaleString('en-IN', {minimumFractionDigits: 2})} g`
        } else {
            return ''
        }
    };

    const getServiceWeightGramBodyTemplate = rowData => {
        if (rowData.serviceWeight) {
            return `${rowData.serviceWeight?.toLocaleString('en-IN', {minimumFractionDigits: 2})} g`
        } else {
            return ''
        }
    };

    const getServiceChargeGramBodyTemplate = rowData => {
        if (rowData.serviceCharge) {
            return `${rowData.serviceCharge?.toLocaleString('en-IN', {minimumFractionDigits: 2})} g`
        } else {
            return ''
        }
    };
    
    const columns = [
        { field: 'partyId', header: 'Party Id', filter: true, hidden: false, sortable: true, style_width: '8rem' },
        { field: 'partyType', header: 'Party Type', filter: true, hidden: false, sortable: true, style_width: '8rem' },
        { field: 'partyName', header: 'Party Name', filter: true, hidden: false, sortable: true, style_width: '8rem' },
        { field: 'deposits', header: 'Deposits', body: getDepositsGramBodyTemplate, filter: true, hidden: false, sortable: true, headerStyle: 'left', style_width: '8rem', align: 'right' },
        { field: 'withdrawals', header: 'Withdrawals', body: getWithdrawalsGramBodyTemplate, filter: true, hidden: false, sortable: true, headerStyle: 'left', style_width: '8rem', align: 'right' },
        { field: 'balance', header: 'Balance', body: getBalanceGramBodyTemplate, filter: true, hidden: false, sortable: true, headerStyle: 'left', style_width: '8rem', align: 'right' },
        { field: 'serviceWeight', header: 'Service Weight', body: getServiceWeightGramBodyTemplate, filter: true, hidden: false, sortable: true, headerStyle: 'left', style_width: '8rem', align: 'right' },
        { field: 'serviceCharge', header: 'Service Charge', body: getServiceChargeGramBodyTemplate, filter: true, hidden: false, sortable: true, headerStyle: 'left', style_width: '8rem', align: 'right' },

    ]

    useEffect(() => {
        getAllPartyDashboardData();
    }, []);

    const dynamicColumns = columns.map((col,i) => {
        return <Column key={col.field} 
                    field={col.field} 
                    header={col.header}
                    body={col.body} 
                    sortable={col.sortable} 
                    filter={col.filter}
                    hidden={col.hidden} 
                    headerStyle={{justifyContent: col.headerStyle}}
                    style={{ minWidth: col.style_width, justifyContent: col.align }} />;
    });

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <h6 className="mx-0 my-1">Parties</h6>
            </React.Fragment>
        );
    };

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                {/* <Button icon="pi pi-plus" className="p-button-success mr-2 new-add-custom" onClick={openNew} />
                <Button icon="pi pi-save" className="p-button-info save-custom" onClick={saveForm} />
                <Button icon="pi pi-download" className="p-button-help export-custom" onClick={exportExcel} />
                 */}
            </React.Fragment>
        );
    };

    return (
        <div className="dashboard-grid">

            <div className="card">
                <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

                <DataTable
                    ref={dt}
                    value={allPartyDashboardData}
                    size="small"
                    showGridlines
                    dataKey="partyId"
                    paginator
                    rows={50}
                    rowsPerPageOptions={[10, 25, 50]}
                    scrollable
                    scrollDirection="horizontal"
                    height="70px"
                    filterDisplay="row"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} transactions"
                    // header={header}
                    sortField="partyId"
                    sortOrder={1}
                    responsiveLayout="scroll"
                    rowHover={true}
                >
                    {dynamicColumns}
                </DataTable>
            </div>

            
        </div>
    );
}

export default Grid;