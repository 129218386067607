import React from 'react'

function AnalyticsReport() {
  return (
    <div>
        <h1>AnalyticsReport Page...</h1>
    </div>
  )
}

export default AnalyticsReport