import React from 'react'

const CustomerManagement = () => {
  return (
    <div>
        <h1>CustomerManagement Page...</h1>
    </div>
  )
}

export default CustomerManagement