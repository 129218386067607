import React from 'react'

function ServiceChargeManagement() {
  return (
    <div>
        <h1>ServiceChargeManagement Page...</h1>
    </div>
  )
}

export default ServiceChargeManagement