import './datatable.scss';
import { DataGrid, GridToolbar  } from '@mui/x-data-grid';
import { userColumns, userRows } from '../../datatablesource';
import {Link} from 'react-router-dom';
import { useEffect, useState } from 'react';

import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

const Datatable = () => {

    const [data, setData] = useState(userRows);
    // var [filteredData, setFilteredData] = useState([]);

    const handleDelete = (id) => {
        setData(data.filter((item) => item.id !== id));
    };

    const [dateRange, setDateRange] = useState([new Date((new Date()).getFullYear(), (new Date()).getMonth()-3, 1), new Date()]);
    const [startDate, endDate] = dateRange;

    var filteredData = [];

    const setFilteredData = (startDate, endDate) => {
        if (!startDate || !endDate) {
            filteredData = data;
        } else {
            filteredData = data.filter(trns => {
                const mydate = trns.date.split("/").map(n => parseInt(n, 10))
                const trnsdate = new Date(mydate[2], mydate[1]-1, mydate[0])
                return (trnsdate >= startDate && trnsdate <= endDate);
            });
        }
    };

    

    // useEffect(() => {
    //     setFilteredData(startDate, endDate);
    // }, [dateRange])

    const actionColumn = [
        {
            field: "action",
            headerName: "Action",
            width: 200,
            renderCell: (params) => {
                return (
                    <div className="cellAction">
                        <Link to="/transactions/test" style={{textDecoration: "none"}}>
                            <div className="viewButton">View</div>
                        </Link>
                        <div className="deleteButton" onClick={() => handleDelete(params.row.id)}>Delete</div>
                        <div className="editButton">Edit</div>
                    </div>
                )
            }
        }
    ]

  return (
    <div className='datatable'>
        <div className="datatableTitle">
            Add New Transactions
            <div className="datepicker">
                <DatePicker
                    selectsRange={true}
                    startDate={startDate}
                    endDate={endDate}
                    onChange={(update) => {
                        setDateRange(update);
                    }}
                    isClearable={true}
                    maxDate={new Date() }
                    dateFormat="dd/MM/yyyy"
                    on
                />
                <button type='submit' onClick={() => setFilteredData(startDate, endDate)}>Submit</button>
            </div>
            <Link to="/transactions/new" style={{textDecoration: "none"}} className="link">
                Add New
            </Link>
        </div>
        <DataGrid
            className='datagrid'
            rows={data}
            columns={userColumns.concat(actionColumn)}
            pageSize={8}
            rowsPerPageOptions={[5]}
            checkboxSelection
            components={{ Toolbar: GridToolbar }}
            initialState={{
                columns: {
                  columnVisibilityModel: {
                    // Hide columns status and traderName, the other columns will remain visible
                    inserttime: false,
                    updatetime: false,
                    approvetime: false
                  },
                },
              }}
        />
    </div>
  )
}

export default Datatable