import './miniTiles.scss';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';

const MiniTiles = (props) => {
  let data;

  let total = 0;
  if ( props.total !== undefined) {
    total = props.total;
  }

  switch(props.type) {
    case "deposit":
      data = {
        title: "TOTAL DEPOSITS",
        amount: total,
        isMoney: true,
        link: "See details",
        icon: <PeopleAltOutlinedIcon className='icon' style={{color: "blue", backgroundColor: "rgba(51, 102, 255,0.2)"}}/>
      }
      break;
    case "withdrawl":
      data = {
        title: "TOTAL WIDTHDRAWALS",
        amount: total,
        isMoney: true,
        link: "See details",
        icon: <MonetizationOnOutlinedIcon className='icon' style={{color: "crimson", backgroundColor: "rgba(255,0,0,0.2)"}}/>
        // icon: <ShoppingCartOutlinedIcon className='icon' style={{color: "goldenrod", backgroundColor: "rgba(218,165,32,0.2)"}}/>
      }
      break;
    case "balance":
      data = {
        title: "TOTAL BALANCE",
        amount: total,
        isMoney: true,
        link: "See details",
        icon: <MonetizationOnOutlinedIcon className='icon' style={{color: "green", backgroundColor: "rgba(0,128,0,0.2)"}}/>
      }
      break;
    case "service_charge":
      data = {
        title: "SERVICE CHARGE",
        amount: total,
        isMoney: true,
        link: "See details",
        icon: <MonetizationOnOutlinedIcon className='icon' style={{color: "goldenrod", backgroundColor: "rgba(218,165,32,0.2)"}}/>
        // icon: <AccountBalanceWalletOutlinedIcon className='icon' style={{color: "purple", backgroundColor: "rgba(128,0,128,0.2)"}}/>
      }
      break;
    case "service_weight":
      data = {
        title: "SERVICE WEIGHT",
        amount: total,
        isMoney: true,
        link: "See details",
        icon: <MonetizationOnOutlinedIcon className='icon' style={{color: "goldenrod", backgroundColor: "rgba(218,165,32,0.2)"}}/>
        // icon: <AccountBalanceWalletOutlinedIcon className='icon' style={{color: "purple", backgroundColor: "rgba(128,0,128,0.2)"}}/>
      }
      break;
    default:
      break;
  }


  return (
    <div className='tile'>
        <div className='tile-left'>
            <span className='title'>{data.title}</span>
        </div>
        <div className='tile-right'>
            <div className="percentage positive">
                {data.amount} g
            </div>
        </div>
    </div>
  )
}

export default MiniTiles;