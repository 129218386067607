import DriveFolderUploadOutlinedIcon from '@mui/icons-material/DriveFolderUploadOutlined';
import './New.scss';
import { useState } from 'react';

const NewTransaction = () => {

  const [file, setFile] = useState("");
  const [value, setValue] = useState(new Date('2014-08-18T21:11:54'));

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  return (
    <div className='new'>
      <div className="newContainer">
        <div className="top">
          <h1 className="">Add New Transaction</h1>
        </div>
        <div className="bottom">
          <div className="left">
            <img src={file ? URL.createObjectURL(file) : "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"} alt="" />
          </div>
          <div className="right">
            <form>
              <div className="formInput">
                <label htmlFor='file'>Image: <DriveFolderUploadOutlinedIcon className='icon'/></label>
                <input type="file" id='file' onChange={(e)=>setFile(e.target.files[0])} style={{display: 'none'}}/>
              </div>
              <div className="formInput">
                <label>Date</label>
                <input type="date" placeholder="" />
              </div>
              <div className="formInput">
                <label>Party</label>
                <input type="text" placeholder="John Doe" />
              </div>
              {/* <div className="formInput">
                <label>Metal Type</label>
                <input type="text" placeholder="Gold" />
              </div> */}
              <div className="formInput">
                <label>Metal Type</label>
                <select className="dropdownInputStyle">
                    <option selected value="gold">Gold</option>
                    <option value="fine_metal">Fine Metal</option>
                    <option value="gold_bar">Gold Bar</option>
                    <option value="pure_gold">Pure Gold</option>
                </select>
              </div>
              <div className="formInput" style={{display: 'flex'}}>
                <div className="formInputInnerLeft">
                    <label>Weight</label>
                    <input type="number" placeholder="0.00" />
                </div>
                <div className="formInputInnerRight">
                    <select className="dropdownStyle">
                        <option selected value="grams">Grams</option>
                        <option value="kilograms">Kilo Grams</option>
                        <option value="tola">Tola</option>
                    </select>
                </div>
              </div>
              <div className="formInput">
                <label>Service Weight</label>
                <input type="number" placeholder="0.00" />
              </div>
              <div className="formInput">
                <label>Service Charge</label>
                <input type="number" placeholder="0.00" />
              </div>
              <div className="formInput">
                <label>Remark</label>
                <input type="text" placeholder="Comments...." />
              </div>
              

              {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Stack spacing={3}>
                    <DesktopDatePicker
                    label="Date desktop"
                    inputFormat="MM/dd/yyyy"
                    value={value}
                    onChange={handleChange}
                    renderInput={(params) => <TextField {...params} />}
                    />
                </Stack>
                </LocalizationProvider> */}

              <button>Send</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NewTransaction