import './widget.scss';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';

const Widget = (props) => {
  let data;

  //temporary
  // const amount = 100
  // const diff = 20

  const handleNumber = (num) => {
    return num?.toLocaleString('en-IN');
  }

  switch(props.type) {
    case "deposits":
      data = {
        title: "TOTAL DEPOSITS",
        class: "deposits-widget",
        total: handleNumber(props.totalDeposits),
        customer: handleNumber(props.customerDeposits),
        investor: handleNumber(props.investorDeposits),
        isMoney: true,
        link: "See details",
        icon: <PeopleAltOutlinedIcon className='icon' style={{color: "blue", backgroundColor: "rgba(51, 102, 255,0.2)"}}/>
      }
      break;
    case "withdrawals":
      data = {
        title: "TOTAL WIDTHDRAWALS",
        class: "withdrawals-widget",
        total: handleNumber(props.totalWithdrawals),
        customer: handleNumber(props.customerWithdrawals),
        investor: handleNumber(props.investorWithdrawals),
        isMoney: true,
        link: "See details",
        icon: <MonetizationOnOutlinedIcon className='icon' style={{color: "crimson", backgroundColor: "rgba(255,0,0,0.2)"}}/>
        // icon: <ShoppingCartOutlinedIcon className='icon' style={{color: "goldenrod", backgroundColor: "rgba(218,165,32,0.2)"}}/>
      }
      break;
    case "balance":
      data = {
        title: "TOTAL BALANCE",
        class: "balance-widget",
        total: handleNumber(props.totalBalance),
        customer: handleNumber(props.customerBalance),
        investor: handleNumber(props.investorBalance),
        isMoney: true,
        link: "See details",
        icon: <MonetizationOnOutlinedIcon className='icon' style={{color: "green", backgroundColor: "rgba(0,128,0,0.2)"}}/>
      }
      break;
    case "service":
      data = {
        title: "REFINARY SERVICE CHARGE",
        class: "refinary-widget",
        serviceCharge: handleNumber(props.serviceCharge),
        overriddenServiceCharge: handleNumber(props.overriddenServiceCharge),
        isMoney: true,
        link: "See details",
        icon: <MonetizationOnOutlinedIcon className='icon' style={{color: "goldenrod", backgroundColor: "rgba(218,165,32,0.2)"}}/>
        // icon: <AccountBalanceWalletOutlinedIcon className='icon' style={{color: "purple", backgroundColor: "rgba(128,0,128,0.2)"}}/>
      }
      break;
    default:
      break;
  }


  return (
    <div className={'widget ' + data.class}>
        <div className='title'><p>{data.title} : {data.class === "refinary-widget" ? data.serviceCharge : data.total} {"g"}</p></div>
        <div className='widget-container'>
          <div className='left'>
              {data.class === 'refinary-widget' ? 
                <span className='counter'>Overridden Service Charge: {data.overriddenServiceCharge} {"g"}</span> : 
                <span className='counter'>Investor: {data.investor} {"g"}</span>}
              {/* <span className='link'>{data.link}</span> */}
          </div>
          <div className='right'>
              {data.class === 'refinary-widget' ? null :
                <span className='counter'>Customer: {data.customer} {"g"}</span>}
              {/* <div className="percentage positive">
                  <KeyboardArrowUpOutlinedIcon/>
                  {diff} %
                  </div>
                {data.icon} */}
          </div>
        </div>
    </div>
  )
}

export default Widget