import React from "react";
import Chart from "../../components/chart/Chart";
import Featured from "../../components/featured/Featured";
import Widget from "../../components/widget/Widget";
import DashboardChart from "../../components/chart/DashboardChart";
import List from "../../components/table/Table";
import "./Dashboard.scss";

import { useDashboardStore } from "../../stores/dashboardStore";
import { useEffect } from "react";
import Grid from "../../components/datatable/Grid";

const Dashboard = () => {

  const allAggDashboardData = useDashboardStore((state) => state.allAggDashboardData);
  const getAllAggDashboardData = useDashboardStore((state) => state.getAllAggDashboardData);
  const allPartyDashboardData = useDashboardStore((state) => state.allPartyDashboardData);

  useEffect(() => {
    getAllAggDashboardData();
  }, []);

  return(
    <div className="home-main">
      <div className="widgets">
        <Widget 
          type="deposits" 
          totalDeposits={allAggDashboardData?.CUSTOMER?.totalDeposits + allAggDashboardData?.INVESTOR?.totalDeposits}
          customerDeposits={allAggDashboardData?.CUSTOMER?.totalDeposits}
          investorDeposits={allAggDashboardData?.INVESTOR?.totalDeposits}/>
        <Widget type="withdrawals"
          totalWithdrawals={allAggDashboardData?.CUSTOMER?.totalWithdrawals + allAggDashboardData?.INVESTOR?.totalWithdrawals}
          customerWithdrawals={allAggDashboardData?.CUSTOMER?.totalWithdrawals}
          investorWithdrawals={allAggDashboardData?.INVESTOR?.totalWithdrawals}/>
        <Widget type="balance"
          totalBalance={allAggDashboardData?.CUSTOMER?.totalBalance + allAggDashboardData?.INVESTOR?.totalBalance}
          customerBalance={allAggDashboardData?.CUSTOMER?.totalBalance}
          investorBalance={allAggDashboardData?.INVESTOR?.totalBalance}/>
        <Widget type="service"
          serviceCharge={allAggDashboardData?.REFINARY?.totalServiceCharge}
          overriddenServiceCharge={allAggDashboardData?.REFINARY?.totalOverriddenServiceCharge}/>
      </div>
      <div className="grid-main">
        <Grid/>
      </div>
      {/* <div className="charts">
        <Featured/>
        <Chart aspect={2/0.8} title="Last 6 Months (Revenue)"/>
      </div> */}
      {/* <div className="listContainer">
        <div className="listTitle">Parties</div>
        <List/>
      </div> */}
      <div className="dashboard-chart-main">
        <DashboardChart aspect={4/0.9} aggTrxns={allPartyDashboardData}/>
      </div>
    </div>
  )
};

export default Dashboard;
