import create from 'zustand';
import environmnet from '../env.json';

let BACKEND_SERVER = "http://localhost:8800/dev"
if (environmnet.REACT_APP_BACKEND_SERVER && environmnet.NODE_ENV) {
    BACKEND_SERVER = environmnet.REACT_APP_BACKEND_SERVER + "/" + environmnet.NODE_ENV;
}

export const useDashboardStore = create((set) => ({
    allAggDashboardData: {},
    allPartyDashboardData: [],
    getAllAggDashboardData: async () => {
        try {
            const storedToken = localStorage.getItem('authToken');
            if (storedToken) {
                const res = await fetch(`${BACKEND_SERVER}/api/dashboard/balance-details`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${storedToken}`,
                    }
                });

                if (res.status === 401) {
                    localStorage.removeItem('authToken');
                    window.location.href = '/';
                }
                
                let json_res = await res.json();
                set({ allAggDashboardData: json_res.data });
            } else {
                console.log('Token not found');
            }
        } catch (err) {
            console.error('Error fetching data:', err);
        }
    },
    getAllPartyDashboardData: async () => {
        try {
            const storedToken = localStorage.getItem('authToken');
            if (storedToken) {
                const res = await fetch(`${BACKEND_SERVER}/api/dashboard/dashboard-grid`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${storedToken}`,
                    }
                });

                if (res.status === 401) {
                    localStorage.removeItem('authToken');
                    window.location.href = '/';
                }

                let json_res = await res.json();
                set({ allPartyDashboardData: json_res });
            } else {
                console.log('Token not found');
            }            
        } catch (err) {
            console.error('Error fetching data:', err);
        }
    }
}))