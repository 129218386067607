import React, { useContext, useState, useEffect } from "react";
import StockLedger from "./StockLedger";
import ReactLoading from "react-loading";
import {usePartyDataStore, useTrxnStore} from '../../stores/store';

const StockLedgerParent = () => {

  const partyList = usePartyDataStore((state) => state.partyList);
  const getPartyList = usePartyDataStore((state) => state.getPartyList);
  // const isTrxnsDataLoading = useTrxnStore((state) => state.isTrxnsDataLoading);
  
  useEffect(() => {
    getPartyList('both');
  }, [])

  if (partyList.length === 0 ) {
    return (
      <div className="stockledger-page-loading">
        <ReactLoading type="cubes" color="#0000FF" height={100} width={50} /> 
      </div>
    )
  }
 
  return (
    <div>
      <StockLedger/>
    </div>
    )
}

export default StockLedgerParent