import React, { useContext, useState, useEffect } from "react";
import './Refinary.scss';

import MiniTiles from '../../components/minitiles/MiniTiles';
import RefinaryCustomerInfo from "../../components/info/RefinaryCustomerInfo";
import RefinaryDataTable from "../../components/edit_datatable/RefinaryDatatable";

import { useRefinaryDataByPartyStore, useRefinaryTrxnStore, useRefinaryMasterDataStore } from "../../stores/refinaryStore";

const Refinary = () => {
  const partyList = useRefinaryMasterDataStore((state) => state.partyList);

  const aggDataByParty = useRefinaryDataByPartyStore((state) => state.aggDataByParty);
  const getAggDataByParty = useRefinaryDataByPartyStore((state) => state.getAggDataByParty);
  const aggChartDataByParty = useRefinaryDataByPartyStore((state) => state.aggChartDataByParty);
  const getAggChartdataByParty = useRefinaryDataByPartyStore((state) => state.getAggChartdataByParty);
  
  const getServiceChargeData = useRefinaryTrxnStore((state) => state.getServiceChargeData);
  const getRefinaryTrxnsData = useRefinaryTrxnStore((state) => state.getRefinaryTrxnsData);
  
  const tzoffset = (new Date()).getTimezoneOffset() * 60000;

  const sorter2 = (sortBy) => (a, b) => a[sortBy].toLowerCase() > b[sortBy].toLowerCase() ? 1 : -1;
  partyList.sort(sorter2('partyName'))

  const dateFormatBodyTemplate = rowData => {
    let date = rowData.serviceTrxnDate.toString();
    return `${date.substring(6, 8)}/${date.substring(4, 6)}/${date.substring(0, 4)}`
  }

  const statusBodyTemplate = rowData => {
    return <span className={`product-badge status-${rowData.status.toLowerCase()}`}>{rowData.status}</span>;
  };

  const getWeightGramBodyTemplate = rowData => {
    if (rowData.weight) {
      return `${rowData.weight?.toLocaleString('en-IN', {minimumFractionDigits: 2})} g`
    } else {
      return ''
    }
  };

  const getFinePercentBodyTemplate = rowData => {
    if (rowData.finePercentage) {
      return `${rowData.finePercentage?.toLocaleString('en-IN', {minimumFractionDigits: 2})} %`
    } else {
      return ''
    }
  };

  const getServiceWeightBodyTemplate = rowData => {
    if (rowData.serviceWeight) {
      return `${rowData.serviceWeight?.toLocaleString('en-IN', {minimumFractionDigits: 2})} g`
    } else {
      return ''
    }
  
  }
  const getOverriddenServiceWeightBodyTemplate = rowData => {
    if (rowData.overriddenServiceWeight) {
      return `${rowData.overriddenServiceWeight?.toLocaleString('en-IN', {minimumFractionDigits: 2})} g`
    } else {
      return ''
    }
  }

  const getServiceChargeBodyTemplate = rowData => {
    if (rowData.serviceCharge) {
      return `${rowData.serviceCharge?.toLocaleString('en-IN', {minimumFractionDigits: 2})} g`
    } else {
      return ''
    }
  }

  const getOverriddenServiceChargeBodyTemplate = rowData => {
    if (rowData.overriddenServiceCharge) {
      return `${rowData.overriddenServiceCharge?.toLocaleString('en-IN', {minimumFractionDigits: 2})} g`
    } else {
      return ''
    }
  }

  let columns = [
    { field: 'serviceTrxnId', header: 'Service Trxn Id', filter: true, hidden: true, sortable: true, style_width: '12rem' },
    { field: 'serviceTrxnDate', header: 'Service Trxn Date', body: dateFormatBodyTemplate, filter: true, hidden: false, sortable: true, style_width: '10rem' },
    { field: 'metal', header: 'Metal', filter: true, hidden: false, sortable: true, style_width: '8rem' },
    { field: 'serviceId', header: 'Service Id', filter: true, hidden: true, sortable: true, style_width: '12rem' },
    { field: 'service', header: 'Service', filter: true, hidden: true, sortable: true, style_width: '12rem' },
    { field: 'serviceChargeId', header: 'Service Charge Id', filter: true, hidden: true, sortable: true, style_width: '12rem' },
    { field: 'serviceChargeCategory', header: 'Service Charge Category', filter: true, hidden: true, sortable: true, style_width: '12rem' },
    { field: 'serviceChargeUOM', header: 'Service Charge UOM', filter: true, hidden: true, sortable: true, style_width: '12rem' },
    { field: 'basisWeight', header: 'Basis Weight', filter: true, hidden: true, sortable: true, style_width: '12rem' },
    { field: 'serviceChargeType', header: 'Service Charge Type', filter: true, hidden: true, sortable: true, style_width: '12rem' },
    { field: 'serviceChargeRate', header: 'Service Charge Rate', filter: true, hidden: true, sortable: true, headerStyle: 'left', style_width: '12rem', align: 'right' },
    { field: 'weight', header: 'Weight', body: getWeightGramBodyTemplate, filter: true, hidden: false, sortable: true, headerStyle: 'left', style_width: '8rem', align: 'right' },
    { field: 'finePercentage', header: 'Fine Percent', body: getFinePercentBodyTemplate, filter: true, hidden: false, sortable: true, headerStyle: 'left', style_width: '8rem', align: 'right' },
    { field: 'serviceWeight', header: 'Service Weight', body: getServiceWeightBodyTemplate, filter: true, hidden: false, sortable: true, headerStyle: 'left', style_width: '8rem', align: 'right' },
    { field: 'overriddenServiceWeight', header: 'Overridden Service Wght', body: getOverriddenServiceWeightBodyTemplate, filter: true, hidden: false, sortable: true, headerStyle: 'left', style_width: '12rem', align: 'right' },
    // { field: 'UOM', header: 'UOM', filter: true, hidden: false, sortable: true, style_width: '12rem' },
    { field: 'serviceCharge', header: 'Service Charge', body: getServiceChargeBodyTemplate, filter: true, hidden: false, sortable: true, headerStyle: 'left', style_width: '8rem', align: 'right' },
    { field: 'overriddenServiceCharge', header: 'Overridden Service Charge', body: getOverriddenServiceChargeBodyTemplate, filter: true, hidden: false, sortable: true, headerStyle: 'left', style_width: '12rem', align: 'right' },
    // { field: 'status', header: 'Status', body: statusBodyTemplate, filter: true, hidden: false, sortable: true, style_width: '12rem' },
    { field: 'remarks', header: 'Comments', filter: true, hidden: false, sortable: true, style_width: '12rem' },
    { field: 'dateCreated', header: 'Date Created', filter: true, hidden: true, sortable: true, style_width: '12rem' },
    { field: 'createdBy', header: 'Created By', filter: true, hidden: true, sortable: true, style_width: '12rem' },
    { field: 'dateModified', header: 'Date Modified', filter: true, hidden: true, sortable: true, style_width: '12rem' },
    { field: 'modifiedBy', header: 'Modified By', filter: true, hidden: true, sortable: true, style_width: '12rem' }
  ];
  
  const [dateRange, setDateRange] = useState([new Date((new Date()).getFullYear(), (new Date()).getMonth()-3, 1), new Date()]);
  const [selectedValue, setSelectedValue] = useState(partyList[0].partyId);
  const [partyName, setPartyName] = useState(partyList[0].partyName);
  const [partyType, setPartyType] = useState(partyList[0].partyType);
  const [partyEmail, setPartyEmail] = useState(partyList[0].email);
  const [partyPhone, setPartyPhone] = useState(partyList[0].phone);
  const [partyAddress, setPartyAddress] = useState(partyList[0].address);
  
  getRefinaryTrxnsData(selectedValue, 
    (new Date(dateRange[0] - tzoffset).toISOString().substring(0,10).replace(/-/g,'')), 
    (new Date(dateRange[1] - tzoffset).toISOString().substring(0,10).replace(/-/g,'')));
  
  useEffect(() => {

      let d = new Date();
      let endMonth = d.getMonth()+1;
      let endYear = d.getFullYear();
      let startMonth = endMonth-1;
      let startYear = endYear-1;
        
      getAggChartdataByParty(selectedValue, startMonth, startYear, endMonth, endYear);
      getAggDataByParty(selectedValue);
      getServiceChargeData(selectedValue, 'SRC001');
      
      const newPartyList = partyList.filter((el) => {
        return el.partyId == selectedValue
      });
      setPartyName(newPartyList[0].partyName);
      setPartyType(newPartyList[0].partyType);
      setPartyEmail(newPartyList[0].email);
      setPartyPhone(newPartyList[0].phone);
      setPartyAddress(newPartyList[0].address);
    }, [selectedValue]);
          
    return (
      <div className='stockledgerMain'>
        <div className='stockledgerTop'>
          <div className="singleContainer">
            <div className="top">
              <div className="left">
                  <label>Customer: </label>
                  <select className="dropdownInputStyle" onChange={(e) => setSelectedValue(e.target.value)} value={selectedValue}>
                    {partyList.map((option, key) => {
                        return <option key={key} value={option.partyId} >{option.partyName}</option>;
                    })}
                  </select>
              </div>
              <div className="right">
                <div className="mini-tiles">
                  <MiniTiles type="service_weight" total={aggDataByParty?.serviceWeight}/>
                  <MiniTiles type="service_charge" total={aggDataByParty?.serviceCharge}/>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <RefinaryCustomerInfo partyId={selectedValue} name={partyName} type={partyType} email={partyEmail} phone={partyPhone} address={partyAddress} />
        </div>

        <div>
          <RefinaryDataTable 
            cols={columns} 
            partyId={selectedValue} 
            partyName={partyName} 
            type={partyType}
            phone={partyPhone}
            address={partyAddress}
          />
        </div>
    </div>
  )
}

export default Refinary