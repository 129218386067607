import Datatable from '../../components/datatable/Datatable';
import './List.scss';

const List = () => {
  return (
    <div className='list'>
      <div className="listContainer">
        <Datatable/>
      </div>
    </div>
  )
}

export default List