import React from "react";
import { Route, Routes } from "react-router-dom";
import Dashboard from "./pages/dashboard/Dashboard";
import RefinaryParent from "./pages/refinary/RefinaryParent";
import StockLedgerParent from "./pages/stockLedger/StockLedgerParent";
import CoinMinting from "./pages/coinMinting/CoinMinting";
import BangleManufacturing from "./pages/bangleManufacturing/BangleManufacturing";

import InvestorManagement from "./pages/admin/investorManagement/InvestorManagement";
import CustomerManagement from "./pages/admin/customerManagement/CustomerManagement";
import ServiceChargeManagement from "./pages/admin/serviceChargeManagement/ServiceChargeManagement";

import AnalyticsReport from "./pages/reports/analyticsReport/AnalyticsReport";
import ComparisonReport from "./pages/reports/comparisonReport/ComparisonReport";

import PartyList from "./pages/list/PartyList";
import NewParty from "./pages/new/NewParty";
import NewTransaction from "./pages/new/NewTransaction";
import Single from "./pages/single/Single";
import List from "./pages/list/List";

import "./pages/dashboard/Dashboard.scss"

function MainRoutes() {
    return (
        <div className="home">
            <div className="homeContainer">
                <Routes>
                    <Route path="/">
                        <Route index element={<Dashboard/>} />
                        <Route path="stockledger" element={<StockLedgerParent/>} /> 
                        <Route path="refinary" element={<RefinaryParent/>} /> 
                        <Route path="coinminting" element={<CoinMinting/>}/>
                        <Route path="banglemanufacturing" element={<BangleManufacturing/>}/>

                        <Route path="investormanagement" element={<InvestorManagement/>}/>
                        <Route path="customermanagement" element={<CustomerManagement/>}/>
                        <Route path="servicechargemanagement" element={<ServiceChargeManagement/>}/>

                        <Route path="analyticsReport" element={<AnalyticsReport/>}/>
                        <Route path="comparisonReport" element={<ComparisonReport/>}/>

                        <Route path="parties">
                            <Route index element={<PartyList/>}/>
                            <Route path=":userId" element={<Single/>}/>
                            {/* <Route path="new" element={<New inputs={userInputs} title="Add New Party"/>}/> */}
                            <Route path="new" element={<NewParty/>}/>
                        </Route>
                        <Route path="transactions">
                            <Route index element={<List/>}/>
                            <Route path=":productId" element={<Single/>}/>
                            {/* <Route path="new" element={<New inputs={productInputs} title="Add New Transactions"/>}/> */}
                            <Route path="new" element={<NewTransaction/>}/>
                        </Route>
                    </Route>
                </Routes>
            </div>
        </div>
    )
}

export default MainRoutes;