import Chart from '../chart/Chart';
import BiAxialChart from '../chart/BiAxialChart';
import React, { useContext, useState, useEffect } from "react";
import ReactLoading from "react-loading";
import './customerInfo.scss';
// import { useDataByPartyStore } from '../../stores/store';
import { useRefinaryDataByPartyStore } from '../../stores/refinaryStore';

const RefinaryCustomerInfo = (props) => {

  const aggChartDataByParty = useRefinaryDataByPartyStore((state) => state.aggChartDataByParty); 
  // const isAggPartyDataLoading = useDataByPartyStore((state) => state.isAggPartyDataLoading);
  
  // if (isAggPartyDataLoading === true) {
  //   return (
  //     <div className="stockledger-page-loading">
  //       <ReactLoading type="cubes" color="#0000FF" height={100} width={50} /> 
  //     </div>
  //   )
  // }

  return (
    <div className='single'>
      <div className="singleContainer">
        <div className="top">
          <div className="left">
            <div className="editButton">Edit</div>
            <h1 className="title">Customer Information</h1>
            <div className="item">
              <img src="" alt="" className="itemImg" />
              <div className="details">
                <h3 className="itemTitle">{props.name}</h3>
                <div className="detailItem">
                  <span className="itemKey">Type:</span>
                  <span className="itemValue">{props.type}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Email:</span>
                  <span className="itemValue">{props.email}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Phone:</span>
                  <span className="itemValue">{props.phone}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Address:</span>
                  <span className="itemValue">{props.address}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="right">
            <BiAxialChart aspect={4/0.9} title="User Spending (Last 6 Months)" aggTrxns={aggChartDataByParty}/>
          </div>
        </div>
        {/* <div className="bottom">
          <h1 className="title">Last Transactions</h1>
          <List/>
        </div> */}
      </div>
    </div>
  )
}

export default RefinaryCustomerInfo;