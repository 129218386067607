import React from "react";
import MainRoutes from "../../mainRoutes";
import Sidebar from "../sidebar/Sidebar";
import "./Layout.scss"

function Layout(props) {
    return (
        <div className="main-layout-panel">
            <div className="left-panel-main">
                <Sidebar onLogout={props.onLogout}/>
            </div>
            <div className="right-panel-main">
                <MainRoutes />
            </div>
        </div>
    )
}

export default Layout;