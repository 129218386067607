import "./datatable.scss";
import React from 'react';
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { userColumns, userRows } from "../../partydatatablesource";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import environmnet from '../../env.json';

const PartyDatatable = () => {
  const [data, setData] = useState(userRows);
  const [parties, setParties] = useState([]);
  const axiosInstance = axios.create({baseURL: environmnet.REACT_APP_BACKEND_SERVER});

  const getData = () => {
    fetch('194.163.34.136/api/getPartyData', {mode: 'cors'})
      .then((res) => res.json())
      .then((res) => {
        setParties(res)
      })
  }

  useEffect(() => {
    getData()
  }, [])

  const handleDelete = (id) => {
    setData(data.filter((item) => item.id !== id));
  };

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            {/* <Link to="/parties/test" style={{textDecoration: "none"}}>
                            <div className="viewButton">View</div>
                        </Link> */}
            <div className="editButton">Edit</div>
            <div
              className="deleteButton"
              onClick={() => handleDelete(params.row.id)}
            >
              Delete
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <div className="datatable">
      <div className="datatableTitle">
        Add New Party
        <Link
          to="/parties/new"
          style={{ textDecoration: "none" }}
          className="link"
        >
          Add New
        </Link>
      </div>
      <DataGrid
        getRowId={(row) => row.party_id}
        className="datagrid"
        rows={parties}
        columns={userColumns.concat(actionColumn)}
        pageSize={8}
        rowsPerPageOptions={[5]}
        checkboxSelection
      />
    </div>
  );
};

export default PartyDatatable;
