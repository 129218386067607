import React, { useContext, useState, useEffect } from "react";
import Refinary from './Refinary';
import ReactLoading from "react-loading";
import { useRefinaryMasterDataStore } from '../../stores/refinaryStore';

const RefinaryParent = () => {

  const partyList = useRefinaryMasterDataStore((state) => state.partyList);
  const getPartyList = useRefinaryMasterDataStore((state) => state.getPartyList);
  const getMetalList = useRefinaryMasterDataStore((state) => state.getMetalList);
  const getUowList = useRefinaryMasterDataStore((state) => state.getUowList);
  
  useEffect(() => {
    getPartyList('CUSTOMER');
    getMetalList();
    getUowList();
  }, [])

  if (partyList.length === 0) {
    return (
      <div className="stockledger-page-loading">
        <ReactLoading type="cubes" color="#0000FF" height={100} width={50} /> 
      </div>
    )
  }
 
  return (
    <Refinary/>
  )
}

export default RefinaryParent