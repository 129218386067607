import React from 'react';

import './CoinMinting.scss';

const CoinMinting = () => {
    return (
        <div>
            <h1>Coin Minting Page...</h1>
        </div>
    )
}

export default CoinMinting;