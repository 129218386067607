import PartyDatatable from '../../components/datatable/PartyDatatable';
import './List.scss';

const PartyList = () => {
  return (
    <div className='list'>
      <div className="listContainer">
        <PartyDatatable/>
      </div>
    </div>
  )
}

export default PartyList