import React, { useState, useEffect, useRef } from 'react';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { Toolbar } from 'primereact/toolbar';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputNumber } from 'primereact/inputnumber';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import ReactLoading from "react-loading";

import { Dropdown as BootDropdown } from 'react-bootstrap';
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import './DataTableDemo.scss';
import { useTrxnStore } from "../../stores/store";

const ExcelJS = require('exceljs');


const deletedTransactions = [];
const addedTransactions = [];

let updatedRowData = {};
const updatedTransactions = [];

const DataTableCrudDemo = props => {

    const tzoffset = (new Date()).getTimezoneOffset() * 60000;

    const trxnsData = useTrxnStore((state) => state.trxnsData);
    const getTrxnsData = useTrxnStore((state) => state.getTrxnsData);
    const addAddedTransactions = useTrxnStore((state) => state.addAddedTransactions);
    const updateTransaction = useTrxnStore((state) => state.updateTransaction);
    const removeDeletedTransaction = useTrxnStore((state) => state.removeDeletedTransaction);
    const cudTransactions = useTrxnStore((state) => state.cudTransactions);
    const isTrxnsDataLoading = useTrxnStore((state) => state.isTrxnsDataLoading);

    let fileUploaderRef = null;
    const emptyTransaction = {
        trxnId: '',
        firmId: 'FRM001',
        partyId: props.partyId,
        trxnType: '',
        metalId: '',
        metal: '',
        deposits: 0,
        withdrawals: 0,
        balance: 0,
        trxnDate: '',
        weight: 0,
        finePercentage: 0,
        serviceWeight: 0,
        UOW: '',
        status: 'APPROVED',
        remarks: '',
        serviceTrxnId: '',
        activeStatus: 'A',
        createdBy: 'administrator',
        dateCreated: new Date(new Date().getTime() + 5.5 * 60 * 60000).toISOString(),
        modifiedBy: 'administrator',
        dateModified: new Date(new Date().getTime() + 5.5 * 60 * 60000).toISOString(),
    };

    const tableColumns = props.cols;

    const metals = [
        { metal: 'GOLD-FINE', metalId: 'MTL001' },
        { metal: 'GOLD-ORD', metalId: 'MTL002' },
        { metal: 'SILVER-FINE', metalId: 'MTL003' },
        { metal: 'SILVER-ORD', metalId: 'MTL004' },
    ];

    const trxn_types = [
        { transaction: 'DEPOSIT', code: 'DEPOSIT' },
        { transaction: 'WITHDRAWAL', code: 'WITHDRAWAL' },
    ];

    const uows = [
        { uow: 'GRAM', code: 'gram' },
        { uow: 'KILLOGRAM', code: 'killogram' },
    ];


    const [importedCols, setImportedCols] = useState([{ field: '', header: 'Header' }]);
    const [importedData, setImportedData] = useState([]);

    const [dateRange, setDateRange] = useState([new Date((new Date()).getFullYear(), (new Date()).getMonth()-3, 1), new Date()]);

    const [selectedColumns, setSelectedColumns] = useState(tableColumns);
    // const [dynamicColumns, setDynamicColumns] = useState();
    const [products, setProducts] = useState(null);
    const [transaction, setTransaction] = useState(emptyTransaction);
    const [transactions, setTransactions] = useState(trxnsData);
    // const [productDialog, setProductDialog] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const [transactionDialog, setTransactionDialog] = useState(false);
    const [deleteTransactionDialog, setDeleteTransactionDialog] = useState(false);
    const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);
    const [product, setProduct] = useState(emptyTransaction);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [selectedTransactions, setSelectedTransactions] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [metal, setMetal] = useState();
    const [trxnType, setTrxnType] = useState('');
    const [wghtVal, setWghtVal] = useState(0);
    const [finePerVal, setFinePerVal] = useState(0);
    const [srvWghtVal, setSrvWghtVal] = useState(0);
    const [uow, setUow] = useState(uows[0]);
    const [remarks, setRemarks] = useState('');
    const [date, setDate] = useState(new Date());
    const toast = useRef(null);
    const dt = useRef(null);

    const resetState = () => {
        setDate(new Date());
        setMetal('');
        setTrxnType('');
        setWghtVal(0);
        setFinePerVal(0);
        setSrvWghtVal(0);
        setUow(uows[0]);
        setRemarks('');
        setSubmitted(false);
    };

    // useEffect(() => {
    //     let trxns = props.transactions;

    //     (async function() {
    //         await setTransactions(trxns);

    //     })();

    //     generateAddedTransactionsArray();
    // }, [props.transactions, JSON.stringify(tableColumns), JSON.stringify(importedData)]);

    useEffect(() => {

        if (dateRange[0] && dateRange[1]) {
            let tzoffset = (new Date()).getTimezoneOffset() * 60000;
            getTrxnsData(props.partyId, 
              (new Date(dateRange[0] - tzoffset).toISOString().substring(0,10).replace(/-/g,'')), 
              (new Date(dateRange[1] - tzoffset).toISOString().substring(0,10).replace(/-/g,'')));
          }
        generateAddedTransactionsArray();
    }, [JSON.stringify(importedData), dateRange, props.partyId]);


    const columnToggleDropdown = tableColumns.map(col => {
        
        return <BootDropdown.Item key={col.field}>
            <BootstrapSwitchButton checked={!col.hidden} size="xs"
                onChange={(checked) => {
                    var objIndex = tableColumns.findIndex((obj => obj.field === col.field));
                    tableColumns[objIndex].hidden = !checked;
                    setSelectedColumns({...tableColumns});
                }}
            />
            <span className="col-header-name">{col.header}</span>
        </BootDropdown.Item>
    })


    // Input Form Functions ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const handleFinePercentInput = finePerValue => {
        const serviceWeightCalulation = wghtVal * (finePerValue / 100);
        const servWght = !isNaN(serviceWeightCalulation) && isFinite(serviceWeightCalulation) ? serviceWeightCalulation : 0;
        setSrvWghtVal(servWght);
        setFinePerVal(finePerValue);
    };

    const handleServWghtInput = servWghtVal => {
        const finePercentCalculation = (servWghtVal / wghtVal) * 100;
        const finePercent = !isNaN(finePercentCalculation) && isFinite(finePercentCalculation) ? finePercentCalculation : 0;
        setFinePerVal(finePercent);
        setSrvWghtVal(servWghtVal);
    };

    const handleMetalWeightInput = metalWeight => {
        setWghtVal(metalWeight);

        const serviceWeightCalulation = metalWeight * (finePerVal / 100);
        const serviceWeight = !isNaN(serviceWeightCalulation) && isFinite(serviceWeightCalulation) ? serviceWeightCalulation : 0;
        setSrvWghtVal(serviceWeight);

        const finePercentCalculation = (srvWghtVal / metalWeight) * 100;
        const finePercent = !isNaN(finePercentCalculation) && isFinite(finePercentCalculation) ? finePercentCalculation : 0;
        setFinePerVal(finePercent);
    };
    // Input Form Functions ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const formatCurrency = value => {
        return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    };

    const openNew = () => {
        setTransaction(emptyTransaction);
        resetState();
        setSubmitted(false);
        setTransactionDialog(true);
    };

    const hideDialog = () => {
        setSubmitted(false);
        setTransactionDialog(false);
    };

    const hideDeleteProductDialog = () => {
        setDeleteTransactionDialog(false);
    };

    const hideDeleteProductsDialog = () => {
        setDeleteProductsDialog(false);
    };

    const findTrxnType = (deposits, withdrawals) => {
        if (deposits !== 0) {
            return "DEPOSIT";
        } else if (withdrawals !== 0) {
            return "WITHDRAWAL";
        } else {
            return null;
        }
    }

    const getMetalId = (metalName) => {
        let metalObj = metals.filter(el => el.metal === metalName)[0];
        return metalObj.metalId;
    }

    const generateAddTransactionObj = (obj) => {
        if(obj.metal && obj.trxnDate && obj.serviceWeight){
            const addTrxnObj = {
                trxnId: generateTrxnId(),
                firmId: 'FRM001',
                partyId: props.partyId,
                trxnType: findTrxnType(obj.deposits, obj.withdrawals),
                metalId: getMetalId(obj.metal),
                metal: obj.metal,
                deposits: obj.deposits,
                withdrawals: obj.withdrawals,
                balance: obj.deposits - obj.withdrawals,
                trxnDate: obj.trxnDate,
                weight: ('weight' in obj) ? obj.weight : 0,
                finePercentage: ('finePercentage' in obj) ? obj.finePercentage : 0,
                serviceWeight: obj.serviceWeight,
                UOW: 'GRAM',
                status: 'APPROVED',
                remarks: obj.remarks,
                serviceTrxnId: '',
                activeStatus: 'A',
                createdBy: 'administrator',
                dateCreated: new Date(new Date().getTime() + 5.5 * 60 * 60000).toISOString(),
                modifiedBy: 'administrator',
                dateModified: new Date(new Date().getTime() + 5.5 * 60 * 60000).toISOString(),
                type: 'added-trxns'
            };
            return addTrxnObj;
        }
    }

    const addTransaction = () => {
        setSubmitted(true);

        if (metal.metal && trxnType.code && srvWghtVal) {
            const transaction = {
                trxnId: generateTrxnId(),
                firmId: 'FRM001',
                partyId: props.partyId,
                trxnType: trxnType.code,
                metalId: metal.metalId,
                metal: metal.metal,
                deposits: getDeposits(),
                withdrawals: getWithdrawls(),
                balance: getDeposits() - getWithdrawls(),
                trxnDate: convertDate(),
                weight: wghtVal,
                finePercentage: finePerVal,
                serviceWeight: srvWghtVal,
                UOW: uow.uow,
                status: 'APPROVED',
                remarks: remarks,
                serviceTrxnId: '',
                activeStatus: 'A',
                createdBy: 'administrator',
                dateCreated: new Date(new Date().getTime() + 5.5 * 60 * 60000).toISOString(),
                modifiedBy: 'administrator',
                dateModified: new Date(new Date().getTime() + 5.5 * 60 * 60000).toISOString(),
                type: 'added-trxns'
            };
            addedTransactions.push(transaction);
            // const trxns = transactions;
            // trxns.push(transaction);
            setTransactionDialog(false);
            // setTransactions(trxns);
            addAddedTransactions(transaction);
            // setTransaction(emptyTransaction);
            resetState();
        }
        // setSubmitted(false);
    };

    const handleUpdateTransaction = () => {
        const transaction = {
            trxnId: updatedRowData.trxnId,
            firmId: updatedRowData.firmId,
            partyId: props.partyId,
            partyName: props.partyName,
            partyType: props.type,
            trxnType: trxnType.code,
            metalId: metal.metalId,
            metal: metal.metal,
            deposits: getDeposits(),
            withdrawals: getWithdrawls(),
            balance: getDeposits() - getWithdrawls(),
            UOW: uow.uow,
            trxnDate: convertDate(),
            weight: wghtVal,
            finePercentage: finePerVal,
            serviceWeight: srvWghtVal,
            status: 'APPROVED',
            remarks: remarks,
            serviceTrxnId: '',
            activeStatus: 'A',
            createdBy: updatedRowData.createdBy,
            dateCreated: updatedRowData.dateCreated,
            modifiedBy: 'administrator',
            dateModified: new Date(new Date().getTime() + 5.5 * 60 * 60000).toISOString(),
            type: 'updated-trxns'
        };

        let exists = addedTransactions.some(el => el.trxnId === transaction.trxnId);
        if (exists) {
            const replaceIndex = addedTransactions.findIndex(el => el.trxnId === transaction.trxnId);
            addedTransactions.splice(replaceIndex, 1, transaction);
        } else {
            updatedTransactions.push(transaction);
        }

        // let updateIndex = transactions.findIndex(el => el.trxnId === transaction.trxnId);
        // transactions[updateIndex] = transaction;
        updateTransaction(transaction);
        setTransactionDialog(false);
        // setTransactions(trxns);
        setTransaction(emptyTransaction);
        setIsUpdating(false);
    };

    const editTransaction = transactionRowData => {
        let tDate = transactionRowData.trxnDate.toString();
        setDate(new Date(tDate.substring(0, 4), tDate.substring(4, 6) - 1, tDate.substring(6, 8)));
        setMetal(metals.filter(el => el.metalId === transactionRowData.metalId)[0]);
        setTrxnType(trxn_types.filter(el => el.transaction === transactionRowData.trxnType)[0]);
        setWghtVal(transactionRowData.weight);
        setFinePerVal(transactionRowData.finePercentage);
        setUow(uows.filter(el => el.uow === transactionRowData.UOW)[0]);
        setSrvWghtVal(transactionRowData.serviceWeight);
        setRemarks(transactionRowData.remarks);

        updatedRowData = transactionRowData;
        setTransactionDialog(true);
        setIsUpdating(true);
    };

    const confirmDeleteProduct = transaction => {
        setTransaction(transaction);
        setDeleteTransactionDialog(true);
    };

    const deleteInlineTransaction = () => {
        // let _transactions = transactions.filter(val => val.trxnId !== transaction.trxnId);
        // setTransactions(_transactions);
        removeDeletedTransaction(transaction);
        let exists = addedTransactions.some(el => el.trxnId === transaction.trxnId);
        if (exists) {
            const removeIndex = addedTransactions.findIndex(el => el.trxnId === transaction.trxnId);
            addedTransactions.splice(removeIndex, 1);
        } else {
            deletedTransactions.push(transaction.trxnId);
        }
        setDeleteTransactionDialog(false);
        setTransaction(emptyTransaction);
        // toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Product Deleted', life: 3000 });
    };

    const generateTrxnId = () => {
        let trxnId = 'TRXN' + new Date().getTime().toString();
        return trxnId;
    };

    const convertDate = () => {
        let tzoffset = new Date().getTimezoneOffset() * 60000;
        let date_time_zone = new Date(date - tzoffset).toISOString().substring(0, 10).replace(/-/g, '');
        return date_time_zone;
    };

    const getDeposits = () => {
        if (trxnType.code === 'DEPOSIT') {
            return srvWghtVal;
        } else {
            return 0;
        }
    };

    const getWithdrawls = () => {
        if (trxnType.code === 'WITHDRAWAL') {
            return srvWghtVal;
        } else {
            return 0;
        }
    };


    const generateAddedTransactionsArray = async() => {
        let sleep = (delay) => new Promise((resolve) => setTimeout(resolve, delay));

        if (importedData.length > 0) {
            for(let i=0; i < importedData.length; i++) {
                await sleep(1000);
                addedTransactions.push(generateAddTransactionObj(importedData[i]));
            }
        }
        
        // const trxns = transactions.concat(addedTransactions);
        // setTransactions(trxns);
        addAddedTransactions(addedTransactions);
        setTransaction(emptyTransaction);
        resetState();
        // setImportedData([]);
    }

    const importExcel = (e) => {
        const file = e.files[0];

        import('xlsx').then(xlsx => {
            const reader = new FileReader();
            reader.onload = (e) => {
                const wb = xlsx.read(e.target.result, { type: 'array' });
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                const data = xlsx.utils.sheet_to_json(ws, { header: 1 });

                // Prepare DataTable
                const cols = data[0];
                data.shift();

                let _importedCols = cols.map(col => ({ field: col, header: toCapitalize(col) }));
                let _importedData = data.map(d => {
                    return cols.reduce((obj, c, i) => {
                        obj[c] = d[i];
                        return obj;
                    }, {});
                });

                _importedData = _importedData.filter(item => item.metal !== undefined && item.trxnDate !== undefined)

                setImportedCols(_importedCols);
                setImportedData(_importedData);
            };

            reader.readAsArrayBuffer(file);
        });
        fileUploaderRef.clear();
    }

    const toCapitalize = (s) => {
        return s.charAt(0).toUpperCase() + s.slice(1);
    }

    const exportExcel = () => {
        let export_trxn_data = [];
        const export_columns_list = ['trxnDate', 'metal', 'serviceWeight', 'deposits', 'withdrawals', 'balance', 'status', 'remarks'];
        let srno = 1;

        for (let trxn_idx in trxnsData) {
            let t = trxnsData[trxn_idx];
            let obj = {}
            obj['srno'] = srno
            for (let col_idx in export_columns_list) {
                let col_key = export_columns_list[col_idx];
                obj[col_key] = t[col_key];
            }
            export_trxn_data.push(obj);
            srno += 1;
        }

        // Calculating SUM of deposits, withdrawals, balance and serviceWeights
        const result = export_trxn_data.reduce(
            (accumulator, transaction) => {
              accumulator.deposits += transaction.deposits;
              accumulator.withdrawals += transaction.withdrawals;
              accumulator.balance += transaction.balance;
              accumulator.serviceWeight += transaction.serviceWeight;
              return accumulator;
            },
            { deposits: 0, withdrawals: 0, balance: 0, serviceWeight: 0}
        );

        const convert_num_to_string_india = (weight) => {
            return Intl.NumberFormat('en-IN').format(weight.toFixed(3));
        };
        
        const workbook = new ExcelJS.Workbook();
        const sheet = workbook.addWorksheet("Stockledger");

        // Merging cells for Sheet Header
        sheet.mergeCells('A1:I2');

        sheet.mergeCells('A3:D4');
        sheet.mergeCells('A5:D6');
        sheet.mergeCells('A7:D8');

        sheet.mergeCells('E3:F4');
        sheet.mergeCells('E5:F6');
        sheet.mergeCells('E7:F8');

        sheet.mergeCells('H3:I4');
        sheet.mergeCells('H5:I6');
        sheet.mergeCells('H7:I8');

        const alignment = {
            horizontal: 'left', 
            vertical: 'middle'
        };

        const font = {
            name: 'Arial Black',
            family: 3,
            size: 12,
        }

        const sheetHeader = sheet.getCell('A1');
        sheetHeader.value = 'Stock Ledger';
        sheetHeader.alignment = {
            horizontal: 'center',
            vertical: 'middle'
        }
        sheetHeader.font = {
            name: 'Arial Black',
            family: 2,
            size: 14
        }

        const headingPartyName = sheet.getCell('A3');
        headingPartyName.value = 'Party Name: '+props.partyName;
        headingPartyName.alignment = alignment;
        headingPartyName.font = font;

        const headingDateRange = sheet.getCell('A5');
        headingDateRange.value = 'Date Range: ' + (new Date(dateRange[0] - tzoffset).toLocaleDateString()) + ' - ' + (new Date(dateRange[1] - tzoffset).toLocaleDateString());
        headingDateRange.alignment = alignment;
        headingDateRange.font = font;

        const headingPhone = sheet.getCell('E3');
        headingPhone.value = 'Phone: ' + props.phone;
        headingPhone.alignment = alignment;
        headingPhone.font = font;

        const headingAddress = sheet.getCell('E5');
        headingAddress.value = 'Address: ' + props.address;
        headingAddress.alignment = alignment;
        headingAddress.font = font;

        const headingDeposits = sheet.getCell('H3');
        headingDeposits.value = 'Total Deposits: ' + convert_num_to_string_india(result.deposits) + ' grams';
        headingDeposits.alignment = alignment;
        headingDeposits.font = font;

        const headingWithdrawals = sheet.getCell('H5');
        headingWithdrawals.value = 'Total Withdrawals: ' + convert_num_to_string_india(result.withdrawals) + ' grams';
        headingWithdrawals.alignment = alignment;
        headingWithdrawals.font = font;

        const headingBalance = sheet.getCell('H7');
        headingBalance.value = 'Total Balance: ' + convert_num_to_string_india(result.balance) + ' grams';
        headingBalance.alignment = alignment;
        headingBalance.font = font;

        const headers = ['Sr. No.', 'Date', 'Metal', 'Service Weight (g)', 'Deposits (g)', 'Withdrawals (g)', 'Balance (g)', 'Status', 'Remarks'];
        sheet.addRow(headers);

        sheet.getRow(9).font = {
            name: "Sans serif",
            family: 4,
            size: 12,
            bold: true
        }

        var borderStyles = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" }
        };

        // Add data to worksheet
        const rows_data = export_trxn_data.map((item) => [item?.srno, item?.trxnDate, item?.metal, item?.serviceWeight, item?.deposits, item?.withdrawals, item?.balance, item?.status, item?.remarks]);
        rows_data.forEach((row) => sheet.addRow(row));

        const footerTotals = ['', '', '',  parseFloat(result.serviceWeight.toFixed(3)), parseFloat(result.deposits.toFixed(3)), parseFloat(result.withdrawals.toFixed(3)), parseFloat(result.balance.toFixed(3)), '', ''];
        sheet.addRow(footerTotals);

        // Setting Column Widths
        const column_width = [10, 15, 15, 20, 20, 20, 20, 20, 35]
        sheet.columns.forEach((column, i) => {
            column.width = column_width[i];
        });

        // Setting frozen header column
        sheet.views = [
            {state: 'frozen', xSplit: 0, ySplit: 9, showGridLines: false}
        ];

        // Adding Auto filter
        sheet.autoFilter = {
            from: 'A9',
            to: 'I9'
        }

        // Adding table border
        let cols = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I']
        let rows = srno;

        for (let i=0; i<cols.length; i++) {
            for (let j=9; j<=rows+9; j++) {
                let cell = cols[i] + j;
                sheet.getCell(cell).border = borderStyles;
            }
        }

        // Filling column background with colour
        for (let i=0; i<cols.length; i++) {
            let cell = cols[i]+'9';
            sheet.getCell(cell).fill = {
                type: "pattern",
                pattern: "darkHorizontal",
                fgColor: {argb: "74a8fc"}
            }
        }

        const footerMergeCellsLeft = 'A'+(srno+9).toString()+':C'+(srno+9).toString();
        sheet.mergeCells(footerMergeCellsLeft);

        const footerMergeCellsRight = 'H'+(srno+9).toString()+':I'+(srno+9).toString();
        sheet.mergeCells(footerMergeCellsRight);

        const tc = 'A'+(srno+9).toString();
        const totalCell = sheet.getCell(tc);
        totalCell.value = 'Totals';
        totalCell.alignment = {horizontal: 'center'};
        totalCell.font = font;

        sheet.getRow(srno+9).font = {
            name: "Sans serif",
            family: 4,
            size: 12,
            bold: true
        }
        
        workbook.xlsx.writeBuffer().then(data => {
            const blob = new Blob([data], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheet.sheet",
            });
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a');
            anchor.href = url;
            anchor.download = 'StockLedger_'+props.partyName.replace(/ /g,"_")+'_'+(new Date(dateRange[0] - tzoffset).toISOString().substring(0,10).replace(/-/g,''))+'_'+(new Date(dateRange[1] - tzoffset).toISOString().substring(0,10).replace(/-/g,''))+'.xlsx';
            anchor.click();
            window.URL.revokeObjectURL(url);
        })
    }

    const saveForm = async () => {
        try {
            const result = await cudTransactions(addedTransactions, updatedTransactions, deletedTransactions);
            
            toast.current.show({ severity: 'success', summary: 'Successful', detail: result.data.msg, life: 3000 });
            addedTransactions.splice(0, addedTransactions.length);
            updatedTransactions.splice(0, updatedTransactions.length);
            deletedTransactions.splice(0, deletedTransactions.length);
            
            // getTrxnsData(props.partyId, props.startDate, props.endDate);
            if (dateRange[0] && dateRange[1]) {
                let tzoffset = (new Date()).getTimezoneOffset() * 60000;
                getTrxnsData(props.partyId, 
                  (new Date(dateRange[0] - tzoffset).toISOString().substring(0,10).replace(/-/g,'')), 
                  (new Date(dateRange[1] - tzoffset).toISOString().substring(0,10).replace(/-/g,'')));
              }
        } catch (error) {
            toast.current.show({ severity: 'error', summary: 'Error', life: 3000 });
        }
    };

    // const confirmDeleteSelected = () => {
    //     setDeleteProductsDialog(true);
    // };

    // const deleteSelectedProducts = () => {
    //     // let _products = products.filter(val => !selectedProducts.includes(val));
    //     let _transactions = transactions.filter(val => !selectedTransactions.includes(val));
    //     // setProducts(_products);
    //     setTransactions(_transactions);
    //     // setDeleteProductsDialog(false);
    //     // setSelectedProducts(null);
    //     toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Products Deleted', life: 3000 });
    // };

    const onCategoryChange = e => {
        let _product = { ...product };
        _product['category'] = e.value;
        setProduct(_product);
    };

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _product = { ...product };
        _product[`${name}`] = val;

        setProduct(_product);
    };

    const onInputNumberChange = (e, name) => {
        const val = e.value || 0;
        let _product = { ...product };
        _product[`${name}`] = val;

        setProduct(_product);
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <h4 className="mx-0 my-1">Manage Transactions</h4>
                <div className="my_date_range">
                    {/* <label htmlFor="trxn_date_range" className="trxn_date_range_class">Date Range:</label> */}
                    <Calendar 
                        id="trxn_date_range" 
                        selectionMode="range"
                        dateFormat="dd/mm/yy"
                        maxDate={new Date()} 
                        value={dateRange} 
                        onChange={(e) => setDateRange(e.value)}>
                    </Calendar>
                </div>
            </React.Fragment>
        );
    };

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button icon="pi pi-plus" className="p-button-success mr-2 new-add-custom" onClick={openNew} />
                <Button icon="pi pi-save" className="p-button-info save-custom" onClick={saveForm} />
                <Button icon="pi pi-download" className="p-button-help export-custom" onClick={exportExcel} />
                <FileUpload
                    ref={ref => fileUploaderRef = ref}
                    chooseOptions={{ icon: 'pi pi-upload', className: 'p-button-primary' }}
                    customUpload="true"
                    mode="basic"
                    name="demo[]"
                    auto
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    // chooseLabel="Import"
                    // className="mr-2 inline-block"
                    uploadHandler={importExcel}
                />
            </React.Fragment>
        );
    };

    const filterColumn = () => {
        return (
            <div className="table-header">
                <BootDropdown className="d-inline" autoClose="outside">
                    <BootDropdown.Toggle id="dropdown-autoclose-outside"></BootDropdown.Toggle>
                    <BootDropdown.Menu>
                        {columnToggleDropdown}
                    </BootDropdown.Menu>
                </BootDropdown>
            </div>
        )
    }

    // const imageBodyTemplate = rowData => {
    //     return (
    //         <img
    //             src={`images/product/${rowData.image}`}
    //             onError={e => (e.target.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png')}
    //             alt={rowData.image}
    //             className="product-image"
    //         />
    //     );
    // };

    // const priceBodyTemplate = rowData => {
    //     return formatCurrency(rowData.price);
    // };

    // const ratingBodyTemplate = rowData => {
    //     return <Rating value={rowData.rating} readOnly cancel={false} />;
    // };

    

    const actionBodyTemplate = rowData => {
        return (
            <React.Fragment>
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editTransaction(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning" onClick={() => confirmDeleteProduct(rowData)} />
            </React.Fragment>
        );
    };

    // const header = (
    //     <div className="table-header">
    //         <h3 className="mx-0 my-1">Manage Products</h3>
    //         <BootDropdown className="d-inline mx-4" autoClose="outside">
    //             <BootDropdown.Toggle id="dropdown-autoclose-outside">Show / Hide Columns</BootDropdown.Toggle>
    //             <BootDropdown.Menu>
    //                 {columnToggleDropdown}
    //             </BootDropdown.Menu>
    //         </BootDropdown>
    //     </div>
    // );

    const dynamicColumns = tableColumns.map((col, i) => {
        
        return <Column key={col.field} 
            field={col.field} 
            header={col.header}
            body={col.body} 
            sortable={col.sortable} 
            filter={col.filter}
            hidden={col.hidden}
            headerStyle={{justifyContent: col.headerStyle}}
            style={{ minWidth: col.style_width, justifyContent: col.align }}
        />;
    });

    const transactionDialogFooter = (
        <React.Fragment>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            {/* <Button label="Add" icon="pi pi-check" className="p-button-text" onClick={saveProduct} /> */}
            {isUpdating ? (
                <Button label="Update" icon="pi pi-check" className="p-button-text" onClick={handleUpdateTransaction} />
            ) : (
                <Button label="Add" icon="pi pi-check" className="p-button-text" onClick={addTransaction} />
            )}
        </React.Fragment>
    );
    const deleteProductDialogFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteInlineTransaction} />
        </React.Fragment>
    );
    // const deleteProductsDialogFooter = (
    //     <React.Fragment>
    //         <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductsDialog} />
    //         <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedProducts} />
    //     </React.Fragment>
    // );
    // const saveProductsDialogFooter = (
    //     <React.Fragment>
    //         <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductsDialog} />
    //         <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedProducts} />
    //     </React.Fragment>
    // );

    const handleRowClassName = (rowData) => {
        if (rowData.type && rowData.type === 'added-trxns') {
            return {'added-trxns': true};
        } else if (rowData.type && rowData.type === 'updated-trxns') {
            return {'updated-trxns': true};
        } else if (rowData.type && rowData.type === 'deleted-trxns') {
            return {'deleted-trxns': true};
        }
    }

    if ( isTrxnsDataLoading === true ) {
        return (
            <div className="stockledger-page-loading">
              <ReactLoading type="cubes" color="#0000FF" height={100} width={50} /> 
            </div>
        )
    }

    return (
        <div className="datatable-crud">
            <Toast ref={toast} />

            <div className="card">
                <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

                <DataTable
                    ref={dt}
                    value={trxnsData}
                    // selection={selectedTransactions}
                    editMode="row"
                    size="small"
                    showGridlines
                    // onSelectionChange={e => setSelectedTransactions(e.value)}
                    dataKey="trxnId"
                    paginator
                    rows={50}
                    rowsPerPageOptions={[10, 25, 50]}
                    scrollable
                    scrollDirection="horizontal"
                    height="70px"
                    filterDisplay="row"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} transactions"
                    // header={header}
                    sortField="trxnDate"
                    sortOrder={-1}
                    responsiveLayout="scroll"
                    rowHover={true}
                    rowClassName={handleRowClassName}
                >
                    {/* <Column selectionMode="multiple" headerStyle={{ width: '6rem' }} exportable={false} frozen={true}></Column> */}
                    <Column header={filterColumn} body={actionBodyTemplate} exportable={false} frozen={true} style={{ width: '5rem', maxWidth: '5rem' }}></Column>
                    {dynamicColumns}
                </DataTable>
            </div>

            <Dialog
                visible={transactionDialog}
                style={{ width: '500px' }}
                header={'Transaction Entry for ' + props.partyName}
                modal
                className="p-fluid"
                footer={transactionDialogFooter}
                onHide={hideDialog}
            >
                {/* {product.image && <img src={`images/product/${product.image}`} onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={product.image} className="product-image block m-auto pb-3" />} */}
                <div className="field">
                    <label htmlFor="trxn_date">Transaction Date:</label>
                    <Calendar id="trxn_date" dateFormat="dd/mm/yy" placeholder="DD/MM/YYYY" maxDate={new Date()} readOnlyInput value={date} onChange={e => setDate(e.value)}></Calendar>
                    {/* {submitted && !date && <small className="p-error">Transaction Date is required.</small>} */}
                </div>
                <div className="field">
                    <label htmlFor="metal">Metal (Metal_Type/Metal_Form)</label>
                    <Dropdown
                        id="metal"
                        optionLabel="metal"
                        value={metal}
                        options={metals}
                        onChange={e => setMetal(e.value)}
                        placeholder="Select Metal Type"
                        autoFocus
                        className={classNames({ 'p-invalid': submitted && !metal })}
                    />
                    {submitted && !metal && <small className="p-error">Metal is required.</small>}
                </div>
                <div className="field">
                    <label htmlFor="trxn_type">Transaction Type</label>
                    <Dropdown
                        id="trxn_type"
                        optionLabel="transaction"
                        value={trxnType}
                        options={trxn_types}
                        onChange={e => setTrxnType(e.value)}
                        placeholder="Select Transaction Type"
                        autoFocus
                        className={classNames({ 'p-invalid': submitted && !trxnType })}
                    />
                    {submitted && !trxnType && <small className="p-error">Transaction Type is required.</small>}
                </div>
                <div className="field wght_section">
                    <div className="wght_left">
                        <label htmlFor="metal_wght" className="metal_weight">
                            Weight
                        </label>
                        <InputNumber id="metal_wght" value={wghtVal} placeholder="0.00" disabled onChange={e => handleMetalWeightInput(e.value)} mode="decimal" locale="en-IN" maxFractionDigits={6} />
                        {/* {submitted && !wghtVal && <small className="p-error">Weight is required.</small>} */}
                    </div>
                    <div className="wght_right">
                        <label htmlFor="uow" className="metal_weight_uow">
                            UOW
                        </label>
                        <Dropdown
                            id="uow"
                            optionLabel="uow"
                            value={uow}
                            options={uows}
                            onChange={e => setUow(e.value)}
                            placeholder="Select UOW"
                            autoFocus
                            className={classNames({ 'p-invalid': submitted && !uow })}
                        />
                        {/* <InputText id="uow" value="GRAM" disabled required autoFocus className={classNames({ 'p-invalid': submitted && !uow }, {})} size="small"/> */}
                        {/* {submitted && !uow && <small className="p-error">UOW is required.</small>} */}
                    </div>
                </div>
                <div className="field wght_section">
                    <div className="wght_left">
                        <label htmlFor="fine_percent" className="fine_percentage">
                            Fine Percentage
                        </label>
                        <div className="p-inputgroup">
                            {/* <InputNumber id="fine_percent" value={finePerVal} disabled={!wghtVal} placeholder="0.00 %" onChange={(e) => handleFinePercentInput(e.value)} mode="decimal" locale="en-IN" maxFractionDigits={4} /> */}
                            <InputNumber
                                id="fine_percent"
                                value={finePerVal}
                                disabled={true}
                                placeholder="0.00 %"
                                onChange={e => handleFinePercentInput(e.value)}
                                mode="decimal"
                                locale="en-IN"
                                maxFractionDigits={4}
                            />
                            <span className="p-inputgroup-addon">%</span>
                        </div>
                    </div>
                    <div className="wght_right">
                        <label htmlFor="service_wght" className="service_weight">
                            Service Weight
                        </label>
                        <div className="p-inputgroup">
                            <InputNumber id="service_wght" value={srvWghtVal} placeholder="0.00" onChange={e => handleServWghtInput(e.value)} mode="decimal" locale="en-IN" maxFractionDigits={4} />
                            <span className="p-inputgroup-addon">g</span>
                        </div>
                        {submitted && !srvWghtVal && <small className="p-error">Service Weight is required.</small>}
                    </div>
                </div>
                <div className="field">
                    <label htmlFor="description">Remarks/Comments</label>
                    <InputTextarea id="description" value={remarks} onChange={e => setRemarks(e.target.value)} required rows={3} cols={20} maxLength={400} />
                    {/* {submitted && !remarks && <small className="p-error">Remarks is required.</small>} */}
                </div>
            </Dialog>

            <Dialog visible={deleteTransactionDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteProductDialogFooter} onHide={hideDeleteProductDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    {product && (
                        <span>
                            Are you sure you want to delete <b>{transaction.name}</b>?
                        </span>
                    )}
                </div>
            </Dialog>

            {/* <Dialog visible={deleteProductsDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteProductsDialogFooter} onHide={hideDeleteProductsDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    {product && <span>Are you sure you want to delete the selected products?</span>}
                </div>
            </Dialog> */}
        </div>
    );
};

export default DataTableCrudDemo;
