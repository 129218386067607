import "./sidebar.scss";
import DashboardIcon from "@mui/icons-material/Dashboard";
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import FiberManualRecordOutlinedIcon from '@mui/icons-material/FiberManualRecordOutlined';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import LocationCityOutlinedIcon from '@mui/icons-material/LocationCityOutlined';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
// import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
// import EventSeatOutlinedIcon from '@mui/icons-material/EventSeatOutlined';
// import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
// import SupervisorAccountOutlinedIcon from '@mui/icons-material/SupervisorAccountOutlined';
// import TrendingUpOutlinedIcon from '@mui/icons-material/TrendingUpOutlined';
// import KeyboardOutlinedIcon from '@mui/icons-material/KeyboardOutlined';
import CompareOutlinedIcon from '@mui/icons-material/CompareOutlined';
// import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
// import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
// import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
// import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
// import QueryStatsOutlinedIcon from "@mui/icons-material/QueryStatsOutlined";
// import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined";
import HealthAndSafetyOutlinedIcon from "@mui/icons-material/HealthAndSafetyOutlined";
import FeedOutlinedIcon from "@mui/icons-material/FeedOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
// import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import { NavLink } from "react-router-dom";
import { useContext } from "react";
import { DarkModeContext } from "../../context/darkModeContext";

const Sidebar = (props) => {
  const { dispatch } = useContext(DarkModeContext);

  const handleLogoutClick = () => {
    props.onLogout();
    window.location.href = '/';
  }

  return (
    <div className="sidebar">
      <div className="top">
        <NavLink to="/" style={{ textDecoration: "none" }}>
          <span className="logo">RRB</span>
        </NavLink>
      </div>
      <hr />
      <div className="center">
        <ul>
          <p className="title">MAIN</p>
          <NavLink to="/" className={({ isActive }) => (isActive ? 'active' : 'inactive')} style={{ textDecoration: "none" }}>
            <li>
              <DashboardIcon className="icon" />
              <span>Dashboard</span>
            </li>
          </NavLink>
          <NavLink to="/stockledger" className={({ isActive }) => (isActive ? 'active' : 'inactive')} style={{ textDecoration: "none" }}>
            <li>
              <DriveFileRenameOutlineOutlinedIcon className="icon" />
              <span>Stock Ledger</span>
            </li>
          </NavLink>
          <p className="title">SERVICES</p>
          <NavLink to="/refinary" className={({ isActive }) => (isActive ? 'active' : 'inactive')} style={{ textDecoration: "none" }}>
            <li>
              <FilterAltOutlinedIcon className="icon" />
              <span>Refinary</span>
            </li>
          </NavLink>
          <NavLink to="/coinminting" className={({ isActive }) => (isActive ? 'active' : 'inactive')} style={{ textDecoration: "none" }}>
            <li>
              <MonetizationOnOutlinedIcon className="icon" />
              <span>Coin-Minting</span>
            </li>
          </NavLink>
          <NavLink to="/banglemanufacturing" className={({ isActive }) => (isActive ? 'active' : 'inactive')} style={{ textDecoration: "none" }}>
            <li>
              <FiberManualRecordOutlinedIcon className="icon" />
              <span>Bangle Manufacturing</span>
            </li>
          </NavLink>
          <p className="title">ADMIN</p>
          <NavLink to="/investormanagement" className={({ isActive }) => (isActive ? 'active' : 'inactive')} style={{ textDecoration: "none" }}>
            <li>
              <LocationCityOutlinedIcon className="icon" />
              <span>Investor Management</span>
            </li>
          </NavLink>
          <NavLink to="/customermanagement" className={({ isActive }) => (isActive ? 'active' : 'inactive')} style={{ textDecoration: "none" }}>
            <li>
              <PermIdentityOutlinedIcon className="icon" />
              <span>Customer Management</span>
            </li>
          </NavLink>
          <NavLink to="/servicechargemanagement" className={({ isActive }) => (isActive ? 'active' : 'inactive')} style={{ textDecoration: "none" }}>
            <li>
              <CreditCardOutlinedIcon className="icon" />
              <span>Service-Charge Management</span>
            </li>
          </NavLink>
          <p className="title">REPORTS</p>
          <NavLink to="/analyticsReport" className={({ isActive }) => (isActive ? 'active' : 'inactive')} style={{ textDecoration: "none" }}>
            <li>
              <SummarizeOutlinedIcon className="icon" />
              <span>Analytics Reports</span>
            </li>
          </NavLink>
          <NavLink to="/comparisonReport" className={({ isActive }) => (isActive ? 'active' : 'inactive')} style={{ textDecoration: "none" }}>
            <li>
              <CompareOutlinedIcon className="icon" />
              <span>Comparison Reports</span>
            </li>
          </NavLink>
          
          {/* <li>
            <BorderColorOutlinedIcon className="icon" />
            <span>Orders</span>
          </li>
          <li>
            <LocalShippingOutlinedIcon className="icon" />
            <span>Delivery</span>
          </li> */}
          {/* <p className="title">USEFUL</p>
          <li>
            <QueryStatsOutlinedIcon className="icon" />
            <span>Stats</span>
          </li>
          <li>
            <NotificationsActiveOutlinedIcon className="icon" />
            <span>Notifications</span>
          </li>
           */}
          <p className="title">SYSTEM</p>
          <li>
            <HealthAndSafetyOutlinedIcon className="icon" />
            <span>Health</span>
          </li>
          <li>
            <FeedOutlinedIcon className="icon" />
            <span>Logs</span>
          </li>
          <li>
            <SettingsOutlinedIcon className="icon" />
            <span>Settings</span>
          </li>
          <p className="title">USER</p>
          <li>
            <AccountCircleOutlinedIcon className="icon" />
            <span>Profile</span>
          </li>
          <li onClick={handleLogoutClick}>
            <LogoutOutlinedIcon className="icon" />
            <span>Logout</span>
          </li>
        </ul>
      </div>
      <div className="bottom">
        <div
          className="colorOptions"
          onClick={() => dispatch({ type: "LIGHT" })}
        ></div>
        <div
          className="colorOptions"
          onClick={() => dispatch({ type: "DARK" })}
        ></div>
      </div>
    </div>
  );
};

export default Sidebar;
