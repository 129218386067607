import React from 'react'

function InvestorManagement() {
  return (
    <div>
        <h1>InvestorManagement Page...</h1>
    </div>
  )
}

export default InvestorManagement