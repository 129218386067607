import React from 'react';

import './BangleManufacturing.scss';

const BangleManufacturing = () => {
    return (
        <div>
            <h1>Bangle Manufacturing Page...</h1>
        </div>
    )
}

export default BangleManufacturing;